import { Box, Divider, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getFullName } from '../../util/stringUtils';

type Props = {
    enquiryType?: string;
    clientName?: string;
};
export default function EnquiryHeaderSection({ enquiryType, clientName }: Readonly<Props>) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 5 }}>
            <Typography variant='h5' component='h1'>
                {enquiryType}
            </Typography>
            <Box sx={{ my: '4px' }}>
                <Divider orientation='vertical' sx={{ borderRightWidth: 2, borderColor: grey[900] }} />
            </Box>
            <Typography
                variant='h5'
                component='h1'
                style={{
                    textAlign: 'center',
                    overflow: 'hidden',
                    maxWidth: 400,
                    textOverflow: 'ellipsis',
                    alignContent: 'center',
                    whiteSpace: 'nowrap',
                    paddingRight: 1,
                }}
            >
                {getFullName(clientName)}
            </Typography>
        </Box>
    );
}
