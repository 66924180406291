import { Avatar, Box, Button, Drawer, Typography } from '@mui/material';
import { Client } from '../../apis/clients';
import { AddCircleOutline } from '@mui/icons-material';
import { useAppSelector } from '../../store/reducer/Hooks';
import { useMemo, useState } from 'react';
import { upperCase } from 'lodash';
import ClientFields from './ClientFields';
import ClientBreakdown from './ClientBreakdown';
import { ContactDetails } from '../../types/Types';
import InvoiceForm from '../CreateInvoice/InvoiceForm';
import { blue } from '@mui/material/colors';

export type LoadedClientPageProps = {
    data: Client;
};

export default function LoadedClientPage(props: LoadedClientPageProps) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { invoiceUpdateAllowed } = permissions;
    const [client, setClient] = useState(props.data);

    const [open, setOpen] = useState(false);

    const updateClientContactDetails = (updatedContactDetails: ContactDetails) => {
        setClient({
            ...client,
            contactDetails: updatedContactDetails,
        });
    };

    const refreshClient = (updatedClient: Client) => {
        setClient(updatedClient);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const initials = useMemo(() => {
        const parts = client.displayName.split(' ');
        parts.length = Math.min(parts.length, 2);
        return parts.map((p) => upperCase(p.charAt(0)));
    }, [client]);

    return (
        <>
            <Box sx={{ pb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ textAlign: 'center', mt: '5px' }}>
                            <Avatar sx={{ bgcolor: blue['200'], width: '64px', height: '64px' }}>{initials}</Avatar>
                        </Box>
                        <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                            <Typography variant='h5' component='h1'>
                                {client.displayName}
                            </Typography>
                            <Typography variant='body1'>{client.insuredType}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {invoiceUpdateAllowed && (
                        <Button
                            variant='contained'
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{ ml: 2 }}
                        >
                            Create policy
                        </Button>
                    )}
                </Box>
            </Box>

            <ClientFields client={client} />

            <ClientBreakdown
                client={client}
                updateClientContactDetails={updateClientContactDetails}
                refreshClient={refreshClient}
            />

            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '75%', maxWidth: '1200px' },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography variant='h6' sx={{ mb: '15px' }}>
                        New invoice
                    </Typography>
                    <InvoiceForm client={client} />
                </Box>
            </Drawer>
        </>
    );
}
