import { Client, noteRequestPromise } from '../../../apis/clients';
import { useFetch } from '../../../hooks/useFetch';
import { ExtendableFetchWrapper } from '../../../components/ExtendableFetchWrapper';
import LoadedNotesPage, { LoadedNotesPageProps } from './LoadedNotesPage';
import { Note } from '../../../types/Types';

interface ClientNotesProps {
    client: Client;
}

export default function Notes({ client }: Readonly<ClientNotesProps>) {
    const state = useFetch(() => noteRequestPromise(client.uuid as string), [client.uuid], {
        canFetch: () => !!client,
    });

    return (
        <ExtendableFetchWrapper<Note[], LoadedNotesPageProps>
            state={state}
            SuccessComponent={LoadedNotesPage}
            client={client}
        />
    );
}
