import { Box, Chip } from '@mui/material';
import LineItem from './LineItem';
import { TimelineObject } from '.';

type Props<T> = {
    dateTime: string;
    lineItems: TimelineObject<T>[];
    hasTail: boolean;
    DayContentComponent: (props: { bodyItem: T }) => JSX.Element;
};

export default function DayLine<T>({ dateTime, lineItems, hasTail, DayContentComponent }: Readonly<Props<T>>) {
    return (
        <Box>
            <Chip label={dateTime} variant='outlined' sx={{ p: 1, height: 30 }} />
            <Box mx={1}>
                {lineItems.map((lineItem, index) => (
                    <Box key={`day-line-${index}`}>
                        <LineItem
                            isFirst={index === 0}
                            lineItem={lineItem}
                            hasTail={hasTail || index < lineItems.length - 1}
                            DayContentComponent={DayContentComponent}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}
