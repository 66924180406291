import { Box, Typography } from '@mui/material';
import { fetchActiveEnquiryConfigurations, fetchEnquiryTypes } from '../../apis/enquiry';
import { FetchWrapper } from '../../components/FetchWrapper';
import { useFetch } from '../../hooks/useFetch';
import EnquiryPageBody from './EnquiryPageBody';
import NewEnquiryAction from './NewEnquiryAction';

export default function Enquiries() {
    const enquiryTypeFetchState = useFetch(() => fetchEnquiryTypes());
    const enquiryConfigFetchState = useFetch(() => fetchActiveEnquiryConfigurations());

    return (
        <Box>
            <Box width='100%' sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, pb: 4 }}>
                <Typography variant='h3' component='h1'>
                    Enquiries
                </Typography>
                <FetchWrapper state={enquiryConfigFetchState} SuccessComponent={NewEnquiryAction} />
            </Box>
            <FetchWrapper state={enquiryTypeFetchState} SuccessComponent={EnquiryPageBody} />
        </Box>
    );
}
