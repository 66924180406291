import { Box, Typography } from '@mui/material';
import { RouteObject } from 'react-router-dom';
import { ClientLeadSummaryEnabled } from '../../apis/clientLead';
import {
    Contacts,
    Email,
    Event,
    Home,
    Insights,
    ManageAccounts,
    Paid,
    PrintOutlined,
    Receipt,
    RunningWithErrors,
} from '@mui/icons-material';
import NoPermission from '../../components/NoPermission';
import { Permissions } from '../../helpers/permission';
import { Page, PageType } from '../../layout';
import ClientDetails from '../ClientDetails';
import ClientSearch from '../ClientSearch';
import CreateInvoice from '../CreateInvoice';
import HomePage from '../Home';
import InvoiceDetails from '../InvoiceDetails';
import InvoiceSearch from '../InvoiceSearch';
import MatchClientLeads from '../MatchClientLeads';
import Report from '../Report';
import UserDetails from '../UserDetails';
import UserSearch from '../UserSearch';
import { InitialData } from './useLoadInitialData';
import Arrears from '../Arrears';
import Cancellations from '../Cancellations';
import Enquiries from '../Enquiries';
import Print from '../Print';
import EnquiryDetails from '../EnquiryDetails';

export type RoutesAndPagesBuildInput = {
    permissions: Permissions;
    initialData: InitialData;
};

export type RoutesAndPagesBuildOutput = {
    routes: RouteObject[];
    pages: Page[];
};

export const buildRoutesAndPages = ({
    permissions,
    initialData,
}: RoutesAndPagesBuildInput): RoutesAndPagesBuildOutput => {
    const {
        dashboardAllowed,
        invoiceAllowed,
        enquiryAllowed,
        clientAllowed,
        userAllowed,
        invoiceUpdateAllowed,
        reportingAllowed,
        paymentMatchingAllowed,
        accountAllowed,
    } = permissions;

    const unsolicitedPaymentsAllowed = paymentMatchingAllowed && initialData.clientLeadSummary.enabled;

    const homeComponent = <HomePage />;
    const invoiceSearchComponent = <InvoiceSearch />;
    const enquiryComponent = <Enquiries />;
    const enquiryDetailComponent = <EnquiryDetails />;
    const arrearsComponent = <Arrears />;
    const cancellationsComponent = <Cancellations />;
    const clientSearchComponent = <ClientSearch />;
    const matchClientLeadsComponent = <MatchClientLeads />;
    const userSearchComponent = <UserSearch />;
    const noPermissionComponent = <NoPermission />;

    const invoiceDetailsComponent = <InvoiceDetails />;
    const createInvoiceComponent = <CreateInvoice />;
    const clientDetailsComponent = <ClientDetails />;
    const userDetailsComponent = <UserDetails />;
    const reportComponent = <Report />;
    const printComponent = <Print />;

    let homeElement;
    if (dashboardAllowed) {
        homeElement = homeComponent;
    } else if (invoiceAllowed) {
        homeElement = invoiceSearchComponent;
    } else if (enquiryAllowed) {
        homeElement = enquiryComponent;
    } else if (clientAllowed) {
        homeElement = clientSearchComponent;
    } else if (unsolicitedPaymentsAllowed) {
        homeElement = matchClientLeadsComponent;
    } else if (userAllowed) {
        homeElement = userSearchComponent;
    } else if (accountAllowed) {
        homeElement = arrearsComponent;
    } else {
        homeElement = noPermissionComponent;
    }

    const routes: RouteObject[] = [];
    const pages: Page[] = [];

    routes.push({
        path: '/',
        element: homeElement,
    });

    if (dashboardAllowed) {
        pages.push(dashboardPage);
    }

    if (invoiceAllowed) {
        pages.push(invoicePage(homeElement === invoiceSearchComponent));

        routes.push(
            {
                path: 'invoices',
                element: invoiceSearchComponent,
            },
            {
                path: 'invoices/:uuid',
                element: invoiceDetailsComponent,
            }
        );

        if (invoiceUpdateAllowed) {
            routes.push({
                path: 'invoices/new',
                element: createInvoiceComponent,
            });
        }
    }

    if (enquiryAllowed) {
        pages.push(enquiryPage(homeElement === invoiceSearchComponent));

        routes.push(
            {
                path: 'enquiries',
                element: enquiryComponent,
            },
            {
                path: 'enquiries/:uuid',
                element: enquiryDetailComponent,
            }
        );
    }

    if (accountAllowed) {
        pages.push(arrearsPage(homeElement === arrearsComponent));
        pages.push(cancellationsPage(homeElement === cancellationsComponent));

        routes.push(
            {
                path: 'arrears',
                element: arrearsComponent,
            },
            {
                path: 'cancellations',
                element: cancellationsComponent,
            }
        );
    }

    if (clientAllowed) {
        pages.push(clientPage(homeElement === clientSearchComponent));

        routes.push(
            {
                path: 'clients',
                element: clientSearchComponent,
            },
            {
                path: 'clients/:uuid',
                element: clientDetailsComponent,
            }
        );
    }

    if (reportingAllowed) {
        pages.push(reportPage(false));

        routes.push({
            path: 'reports',
            element: reportComponent,
        });
    }

    pages.push(printPage(false));
    routes.push({
        path: 'print',
        element: printComponent,
    });

    if (unsolicitedPaymentsAllowed) {
        pages.push(
            matchClientLeadsPage(
                homeElement === matchClientLeadsComponent,
                initialData.clientLeadSummary as ClientLeadSummaryEnabled
            )
        );

        routes.push({
            path: 'match-payments',
            element: matchClientLeadsComponent,
        });
    }

    if (userAllowed) {
        pages.push(userPage(homeElement === userSearchComponent));

        routes.push(
            {
                path: 'users',
                element: userSearchComponent,
            },
            {
                path: 'users/:uuid',
                element: userDetailsComponent,
            }
        );
    }

    if (pages.length === 0) {
        pages.push(dashboardPage);
    }

    return {
        routes,
        pages,
    };
};

const dashboardPage = {
    type: PageType.Dashboard,
    goto: '/',
    routeRegexes: [/^\/$/],
    name: 'Home',
    icon: Home,
};

const invoicePage = (isHomePage: boolean): Page => ({
    type: PageType.Invoice,
    goto: '/invoices',
    routeRegexes: isHomePage ? [/^\/$/, /^\/invoices/] : [/^\/invoices/],
    name: 'Policies',
    icon: Receipt,
});

const enquiryPage = (isHomePage: boolean): Page => ({
    type: PageType.Enquiry,
    goto: '/enquiries',
    routeRegexes: isHomePage ? [/^\/$/, /^\/enquiries/] : [/^\/enquiries/],
    name: 'Enquiries',
    icon: Email,
});

const arrearsPage = (isHomePage: boolean): Page => ({
    type: PageType.Arrears,
    goto: '/arrears',
    routeRegexes: isHomePage ? [/^\/$/, /^\/arrears/] : [/^\/arrears/],
    name: 'Arrears',
    icon: RunningWithErrors,
});

const cancellationsPage = (isHomePage: boolean): Page => ({
    type: PageType.Cancellations,
    goto: '/cancellations',
    routeRegexes: isHomePage ? [/^\/$/, /^\/cancellations/] : [/^\/cancellations/],
    name: 'Cancellations',
    icon: Event,
});

const printPage = (isHomePage: boolean): Page => ({
    type: PageType.Print,
    goto: '/print',
    routeRegexes: isHomePage ? [/^\/$/, /^\/print/] : [/^\/print/],
    name: 'Print',
    icon: PrintOutlined,
});

const matchClientLeadsPage = (isHomePage: boolean, clientLeadSummary: ClientLeadSummaryEnabled): Page => {
    return {
        type: PageType.MatchClientLeads,
        goto: '/match-payments',
        routeRegexes: isHomePage ? [/^\/$/, /^\/match-payments/] : [/^\/match-payments/],
        name: (
            <Box>
                <Typography sx={{ display: 'inline', color: 'inherit' }} component='span'>
                    Match payments
                </Typography>
                {clientLeadSummary.unmatchedInvoiceSummary.totalUnmatched > 0 && (
                    <Typography sx={{ display: 'inline' }} color='error' component='span'>
                        {' '}
                        ({clientLeadSummary.unmatchedInvoiceSummary.totalUnmatched})
                    </Typography>
                )}
            </Box>
        ),
        icon: Paid,
    };
};

const userPage = (isHomePage: boolean): Page => ({
    type: PageType.User,
    goto: '/users',
    routeRegexes: isHomePage ? [/^\/$/, /^\/users/] : [/^\/users/],
    name: 'Users',
    icon: ManageAccounts,
});

const clientPage = (isHomePage: boolean): Page => ({
    type: PageType.Client,
    goto: '/clients',
    routeRegexes: isHomePage ? [/^\/$/, /^\/clients/] : [/^\/clients/],
    name: 'Clients',
    icon: Contacts,
});

const reportPage = (isHomePage: boolean): Page => ({
    type: PageType.Report,
    goto: '/reports',
    routeRegexes: isHomePage ? [/^\/$/, /^\/reports/] : [/^\/reports/],
    name: 'Reports',
    icon: Insights,
});
