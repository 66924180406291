import { Box, Chip, Dialog, Divider, IconButton, Link, Paper, Typography } from '@mui/material';
import { ContactDetails, PhoneNumber } from '../../../types/Types';
import { useState } from 'react';
import { Client } from '../../../apis/clients';
import { Edit } from '@mui/icons-material';
import PhoneNumberForm from './PhoneNumberForm';

type Props = {
    phoneNumbers: PhoneNumber[];
    client: Client;
    updateClientContactDetails: (contactDetails: ContactDetails) => void;
};

export default function PhoneNumbers({ phoneNumbers, client, updateClientContactDetails }: Readonly<Props>) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState({} as PhoneNumber);

    const toggleModal = () => {
        setSelectedPhoneNumber({} as PhoneNumber);
        setOpenModal(!openModal);
    };

    const updatePhoneNumber = (contactDetails: ContactDetails) => {
        updateClientContactDetails(contactDetails);
        toggleModal();
    };

    const editPhoneNumber = (phoneNumber: PhoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        setOpenModal(true);
    };

    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                    <Typography variant='h6' component='h2'>
                        Phone number
                    </Typography>
                </Box>
                <Link href='#' onClick={toggleModal}>
                    Add
                </Link>
            </Box>
            <Paper variant='flat' sx={{ p: 2 }}>
                {!phoneNumbers || phoneNumbers.length === 0 ? (
                    <Typography variant='body2'>No phone numbers</Typography>
                ) : (
                    phoneNumbers.map((phoneNumber, index) => {
                        return (
                            <Box key={phoneNumber.uuid}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 2,
                                        mb: 2,
                                    }}
                                >
                                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                        <Typography variant='body2'>{phoneNumber.number}</Typography>
                                    </Box>
                                    <Box>
                                        {phoneNumber.preferred && (
                                            <Chip label='Preferred' variant='filled' sx={{ mr: 3 }} />
                                        )}

                                        <IconButton
                                            color='primary'
                                            aria-label='Edit email'
                                            onClick={() => editPhoneNumber(phoneNumber)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Box>
                                </Box>

                                {phoneNumbers.length - 1 !== index && <Divider />}
                            </Box>
                        );
                    })
                )}
            </Paper>

            <Dialog open={openModal} onClose={toggleModal}>
                <PhoneNumberForm
                    client={client}
                    callbackPhoneNumberUpdate={updatePhoneNumber}
                    phoneNumber={selectedPhoneNumber}
                />
            </Dialog>
        </Box>
    );
}
