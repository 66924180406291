import {Add, Edit, Search} from '@mui/icons-material';
import {Avatar, Box, Button, Grid, InputAdornment, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, TextField, Tooltip, Typography,} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';
import {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import {Control} from 'react-hook-form/dist/types/form';
import {Client, ClientSearchResult, getClient, listClients} from '../../apis/clients';
import FormattedAddress from '../../components/FormattedAddress';
import {DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange} from '../../util/dateUtils';
import {InvoiceFormFields} from './InvoiceForm';

type ToSectionProps = {
    selectClient: (client: Client | undefined) => void;
    control: Control<InvoiceFormFields>;
    errors: FieldErrors<InvoiceFormFields>;
    client?: Client;
    setOpenSection: (open: boolean) => void;
};

const ToSection = ({control, errors, client, selectClient, setOpenSection}: ToSectionProps) => {
    const [clients, setClients] = useState<ClientSearchResult[]>();
    const [canEditClient, setCanEditClient] = useState(true);

    const searchClients = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        if (query === '') {
            setClients([] as ClientSearchResult[]);
        } else {
            fetchClients(e.currentTarget.value);
        }
    };

    const fetchClient = async (uuid: string) => {
        getClient(uuid)
            .then((response) => {
                setClients(undefined);
                selectClient(response);
                setOpenSection(false);
            })
            .catch(console.log);
    };

    const clearClient = () => {
        selectClient(undefined);
        setClients(undefined);
    };

    const fetchClients = async (query: string) => {
        listClients({query})
            .then((response) => {
                setClients(response.records);
            })
            .catch(console.log);
    };

    useEffect(() => {
        if (client) {
            setCanEditClient(false);
        }
    }, []);

    const renderClientList = () => {
        return clients?.map((client) => {
            return (
                <ListItem disableGutters sx={{padding: 0}} key={client.uuid}>
                    <ListItemButton onClick={() => fetchClient(client.uuid)}>
                        <ListItemAvatar>
                            <Avatar />
                        </ListItemAvatar>
                        <ListItemText
                            primary={client.displayName}
                            secondary={
                                <div style={{overflow: 'hidden', width: '100%', textOverflow: 'ellipsis'}}>
                                    <Tooltip title={client.email}>
                                        <Typography noWrap component='span' variant='body2' color='text.primary'>
                                            {client.email}
                                        </Typography>
                                    </Tooltip>
                                </div>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            );
        });
    };

    return (
        <Paper variant='outlined' sx={{p: 2}}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <Typography variant='body2'>Invoice Number: </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Controller
                                    name='invoiceNumber'
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            {...field}
                                            size='small'
                                            variant='standard'
                                            placeholder='Invoice Number'
                                            // @ts-ignore
                                            error={!!errors.invoiceNumber}
                                            // @ts-ignore
                                            helperText={errors.invoiceNumber?.message}
                                            sx={{width: '150px'}}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant='body2'>Due Date: </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Controller
                                        name='dueDate'
                                        control={control}
                                        render={({field}) => (
                                            <DatePicker
                                                onChange={handleDatePickerChange(field)}
                                                onAccept={handleDatePickerChange(field)}
                                                value={moment(field.value, DATE_SERVER_FORMAT)}
                                                inputRef={field.ref}
                                                sx={{width: '150px'}}
                                                slotProps={{
                                                    textField: {
                                                        id: 'dueDate',
                                                        fullWidth: true,
                                                        size: 'small',
                                                        variant: 'standard',
                                                        helperText: errors?.dueDate?.message,
                                                        error: !!errors?.dueDate,
                                                    },
                                                }}
                                                format={DATE_COMPACT}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </>
                </Grid>
                <Grid item xs={4} sx={{textAlign: 'right', alignItems: 'right'}}>
                    <Typography variant='h6'>Billed to</Typography>
                    {client ? (
                        <>
                            <Box>
                                <Typography variant='subtitle1'>{client.displayName}</Typography>
                                <Typography variant='body2'>
                                    <FormattedAddress address={client.contactDetails.preferredAddress} />
                                </Typography>
                                {canEditClient && (
                                    <>
                                        <br />
                                        <Button onClick={clearClient} startIcon={<Edit />}>
                                            Change
                                        </Button>
                                    </>
                                )}
                            </Box>
                        </>
                    ) : (
                        <div style={{position: 'relative'}}>
                            <TextField
                                placeholder='Search clients'
                                size='small'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                onChange={searchClients}
                                // @ts-ignore
                                error={!!errors.clientIdentifier}
                                // @ts-ignore
                                helperText={errors.clientIdentifier?.message}
                            />
                            {clients && (
                                <Paper sx={{position: 'absolute', zIndex: 9, width: '100%'}}>
                                    <List sx={{padding: 0}}>
                                        {renderClientList()}
                                        <ListItem
                                            disableGutters
                                            sx={{padding: 0, lineHeight: '24px'}}
                                            key='new-client'
                                        >
                                            <ListItemButton onClick={() => setOpenSection(true)}>
                                                <ListItemAvatar>
                                                    <Avatar sx={{bgcolor: '#3659fa'}}>
                                                        <Add />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary='Create new client' />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Paper>
                            )}
                        </div>
                    )}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ToSection;
