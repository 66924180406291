import { Box, Divider, Link, Typography } from '@mui/material';
import { BREAKPOINT } from '../../store/reducer/BreakpointReducer';
import { useAppSelector } from '../../store/reducer/Hooks';
import { Client } from '../../apis/clients';
import moment from 'moment/moment';
import { DATE_FRIENDLY } from '../../util/dateUtils';
import DisplayField from '../../components/DisplayField';

type Props = {
    client: Client;
};

export default function ClientFields({ client }: Readonly<Props>) {
    const { down } = useAppSelector((state) => state.BreakpointReducer);

    return (
        <Box sx={{ display: 'flex', mb: 5, gap: { xs: 1, md: 2 }, flexDirection: { xs: 'column', md: 'row' } }}>
            <DisplayField caption='Created' value={moment(client.createdDate).format(DATE_FRIENDLY)} />

            <Box>
                <Divider orientation={down(BREAKPOINT.md) ? 'horizontal' : 'vertical'} />
            </Box>

            <Box sx={{ pb: 1 }}>
                <Typography variant='caption'>Email</Typography>
                <Box>
                    {client.contactDetails.email?.address ? (
                        <Link target='_blank' href={'mailto:' + client.contactDetails.email.address}>
                            {client.contactDetails.email.address}
                        </Link>
                    ) : (
                        <>Add email address</>
                    )}
                </Box>
            </Box>

            <Box>
                <Divider orientation={down(BREAKPOINT.md) ? 'horizontal' : 'vertical'} />
            </Box>
            <Box sx={{ pb: 1 }}>
                <Typography variant='caption'>Phone number</Typography>
                <Box>
                    <Link target='_blank' href={'tel:' + client.contactDetails.preferredPhoneNumber?.number}>
                        {client.contactDetails.preferredPhoneNumber?.number}
                    </Link>
                </Box>
            </Box>
        </Box>
    );
}
