import { Box, FormControl, FormLabel, Paper } from '@mui/material';
import { useState } from 'react';
import { EnquiryStatus, EnquiryType } from '../../apis/enquiry';
import List from './List';
import EnquiryAssigneeSelect from './selectFilters/EnquiryAssigneeSelect';
import EnquiryCreateDateSelect from './selectFilters/EnquiryCreateDateSelect';
import EnquiryStatusSelect from './selectFilters/EnquiryStatusSelect';
import EnquiryTypeSelect from './selectFilters/EnquiryTypeSelect';

type Props = {
    data: EnquiryType[];
};

export default function EnquiryPageBody({ data }: Readonly<Props>) {
    const [enquiryStatuses, setEnquiryStatuses] = useState<EnquiryStatus[]>([
        EnquiryStatus.NEW,
        EnquiryStatus.IN_PROGRESS,
        EnquiryStatus.ON_HOLD,
        EnquiryStatus.CLOSED,
        EnquiryStatus.SPAM,
    ]);
    const [enquiryTypes, setEnquiryTypes] = useState<string[]>(data.map((enquiryType) => enquiryType.name));
    const [enquiryStartDate, setEnquiryStartDate] = useState<string>('');
    const [enquiryEndDate, setEnquiryEndDate] = useState<string>('');
    const [assigneesSelected, setAssigneesSelected] = useState<string[]>([]);

    return (
        <Paper variant='flat' sx={{ py: 3, px: 2, width: '100%' }}>
            <Box sx={{ display: 'flex', pb: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, width: '100%', gap: 2 }}>
                    <Box sx={{ width: { xs: '100%', lg: '30%' } }}>
                        <FormControl size='small' fullWidth>
                            <FormLabel
                                htmlFor='enquiry-assignee-multiple-checkbox'
                                sx={{ mb: 1, whiteSpace: 'nowrap' }}
                            >
                                Assigned to
                            </FormLabel>
                            <EnquiryAssigneeSelect
                                assigneesSelected={assigneesSelected}
                                setAssigneesSelected={setAssigneesSelected}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: { xs: '100%', lg: '20%' } }}>
                        <FormControl size='small' fullWidth>
                            <FormLabel htmlFor='enquiry-type-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                                Type
                            </FormLabel>
                            <EnquiryTypeSelect
                                availableEnquiryTypes={data}
                                enquiryTypes={enquiryTypes}
                                setEnquiryTypes={setEnquiryTypes}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: { xs: '100%', lg: '20%' } }}>
                        <FormControl size='small' fullWidth>
                            <FormLabel htmlFor='enquiry-status-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                                Status
                            </FormLabel>
                            <EnquiryStatusSelect
                                enquiryStatuses={enquiryStatuses}
                                setEnquiryStatuses={setEnquiryStatuses}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{ width: { xs: '100%', lg: '30%' } }}>
                        <FormControl size='small' fullWidth>
                            <FormLabel sx={{ mb: 1, whiteSpace: 'nowrap' }}>Date created</FormLabel>
                            <EnquiryCreateDateSelect
                                enquiryStartDate={enquiryStartDate}
                                setEnquiryStartDate={setEnquiryStartDate}
                                enquiryEndDate={enquiryEndDate}
                                setEnquiryEndDate={setEnquiryEndDate}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            <List
                enquiryTypes={enquiryTypes}
                enquiryStatuses={enquiryStatuses}
                enquiryStartDate={enquiryStartDate}
                enquiryEndDate={enquiryEndDate}
                assigneesSelected={assigneesSelected}
            />
        </Paper>
    );
}
