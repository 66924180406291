import { capitalize, upperFirst } from 'lodash';

export const firstNameInString = (fullname: string) => {
    return capitalize(fullname.split(' ').filter((word) => word)[0]);
};

export const firstCharsInString = (name: string) => {
    const nameInArray = name.split(' ').filter((word) => word);
    const firstCharsInArray = nameInArray.map((word) => capitalize(word[0]));
    return firstCharsInArray.length == 1
        ? firstCharsInArray[0]
        : firstCharsInArray[0] + firstCharsInArray[firstCharsInArray.length - 1];
};

export const getFullName = (fullname?: string) => {
    return fullname
        ? fullname
              .split(' ')
              .filter((word) => word)
              .map((word) => capitalize(word))
              .join(' ')
        : '';
};

export const getFriendlyEnumsString = (enums: string): string => {
    return upperFirst(enums.toLowerCase().replaceAll('_', ' '));
};
