import { useParams } from 'react-router-dom';
import { getClient } from '../../apis/clients';
import { useFetch } from '../../hooks/useFetch';
import { FetchWrapper } from '../../components/FetchWrapper';
import LoadedClientPage from './LoadedClientPage';

const ClientDetails = () => {
    const { uuid } = useParams<{ uuid?: string }>();

    const state = useFetch(() => getClient(uuid as string), [uuid], {
        canFetch: () => !!uuid,
    });

    return <FetchWrapper state={state} SuccessComponent={LoadedClientPage} />;
};

export default ClientDetails;
