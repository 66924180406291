import { CancellationReason, LoanCancellationRequestStatus } from '../apis/invoice';
import { NotificationStatus } from '../apis/notification';

type Enum = CancellationReason | LoanCancellationRequestStatus | NotificationStatus;

export const renderValues = <T extends Enum>(selected: T[], values: T[], transform: (t: T) => string) => {
    if (selected.length === values.length || selected.length === 0) {
        return 'All';
    }

    return selected.map(transform).join(', ');
};
