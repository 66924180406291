import { Box, Divider, Drawer, IconButton, Paper, Typography } from '@mui/material';
import moment from 'moment/moment';
import { Client, isBusiness, isIndividual, isTrust } from '../../apis/clients';
import DisplayField from '../../components/DisplayField';
import { useAppSelector } from '../../store/reducer/Hooks';
import { useState } from 'react';
import { Edit } from '@mui/icons-material';
import CreateClient from '../CreateClient';
import _ from 'lodash';

export type ClientDetailsCardProps = {
    client: Client;
    refreshClient: (client: Client) => void;
};

const ClientDetailsCard = ({ client, refreshClient }: Readonly<ClientDetailsCardProps>) => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed } = permissions;
    const [editClient, setEditClient] = useState(false);

    const closeEditClient = () => setEditClient(false);
    const openEditClient = () => setEditClient(true);

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <Typography variant='h6' component='h2'>
                            Details
                        </Typography>
                    </Box>
                    {clientUpdateAllowed && (
                        <IconButton color='primary' aria-label='Edit Client' onClick={openEditClient}>
                            <Edit />
                        </IconButton>
                    )}
                </Box>
                <Paper variant='flat' sx={{ p: 2 }}>
                    <Box sx={{ gap: 2 }}>
                        {isIndividual(client) && (
                            <>
                                <DisplayField caption='First name' value={client.personalDetails.givenName} />
                                <DisplayField caption='Last name' value={client.personalDetails.surname} />
                                <DisplayField
                                    caption='Date of birth'
                                    value={
                                        client.personalDetails.dateOfBirth
                                            ? moment(client.personalDetails.dateOfBirth).format('DD MMMM YYYY')
                                            : '-'
                                    }
                                />
                            </>
                        )}
                        {isBusiness(client) && (
                            <>
                                <Typography variant='h6'>Business Details</Typography>
                                <DisplayField caption='Name' value={client.businessName} />
                                <DisplayField caption='Type' value={_.capitalize(_.lowerCase(client.entityType))} />
                                <DisplayField caption='Number' value={client.businessNumber} />

                                <Divider sx={{ mt: 1, mb: 2 }} />

                                <Typography variant='h6'>Authorised individual</Typography>
                                <DisplayField
                                    caption='First name'
                                    value={client.authorizedIndividual.personalDetails.givenName}
                                />
                                <DisplayField
                                    caption='Last name'
                                    value={client.authorizedIndividual.personalDetails.surname}
                                />
                                <DisplayField
                                    caption='Email'
                                    value={client.authorizedIndividual.contactDetails.email?.address}
                                />
                                <DisplayField
                                    caption='Phone number'
                                    value={client.authorizedIndividual.contactDetails.preferredPhoneNumber?.number}
                                />
                            </>
                        )}
                        {isTrust(client) && (
                            <>
                                <Typography variant='h6'>Business Details</Typography>
                                <DisplayField caption='Name' value={client.trustName} />
                                <DisplayField caption='Number' value={client.businessNumber} />

                                <Divider sx={{ mt: 1, mb: 2 }} />

                                <Typography variant='h6'>Authorised individual</Typography>
                                <DisplayField
                                    caption='First name'
                                    value={client.authorizedIndividual.personalDetails.givenName}
                                />
                                <DisplayField
                                    caption='Last name'
                                    value={client.authorizedIndividual.personalDetails.surname}
                                />
                                <DisplayField
                                    caption='Email'
                                    value={client.authorizedIndividual.contactDetails.email?.address}
                                />
                                <DisplayField
                                    caption='Phone number'
                                    value={client.authorizedIndividual.contactDetails.preferredPhoneNumber?.number}
                                />
                            </>
                        )}
                    </Box>
                </Paper>
            </Box>

            <Drawer
                anchor='right'
                open={editClient}
                onClose={closeEditClient}
                PaperProps={{
                    sx: { width: '50%', maxWidth: '1200px' },
                }}
            >
                <CreateClient client={client} callback={refreshClient} />
            </Drawer>
        </>
    );
};

export default ClientDetailsCard;
