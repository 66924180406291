import { Box, Chip, Dialog, Divider, IconButton, Link, Paper, Typography } from '@mui/material';
import { ContactDetails, Email } from '../../../types/Types';
import { useState } from 'react';
import { Client } from '../../../apis/clients';
import EmailForm from './EmailForm';
import { Edit } from '@mui/icons-material';
import { capitalize } from 'lodash';

type Props = {
    emails: Email[];
    client: Client;
    updateClientContactDetails: (contactDetails: ContactDetails) => void;
};

export default function Emails({ emails, client, updateClientContactDetails }: Readonly<Props>) {
    const [openModal, setOpenModal] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState({} as Email);

    const toggleModal = () => {
        setSelectedEmail({} as Email);
        setOpenModal(!openModal);
    };

    const editEmail = (email: Email) => {
        setSelectedEmail(email);
        setOpenModal(true);
    };

    const updateEmail = (contactDetails: ContactDetails) => {
        updateClientContactDetails(contactDetails);
        toggleModal();
    };

    return (
        <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                    <Typography variant='h6' component='h2'>
                        Email
                    </Typography>
                </Box>
                <Link href='#' onClick={toggleModal}>
                    Add
                </Link>
            </Box>
            <Paper variant='flat' sx={{ p: 2 }}>
                {!emails || emails.length === 0 ? (
                    <Typography variant='body2'>No Email</Typography>
                ) : (
                    emails.map((email, index) => {
                        return (
                            <Box key={email.uuid}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 2,
                                        mb: 2,
                                    }}
                                >
                                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                                        <Typography variant='body2'>
                                            {email.address}
                                            <br />
                                            Type: {capitalize(email.emailType)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {email.preferred && <Chip label='Preferred' variant='filled' sx={{ mr: 3 }} />}

                                        <IconButton
                                            color='primary'
                                            aria-label='Edit email'
                                            onClick={() => editEmail(email)}
                                        >
                                            <Edit />
                                        </IconButton>
                                    </Box>
                                </Box>

                                {emails.length - 1 !== index && <Divider />}
                            </Box>
                        );
                    })
                )}
            </Paper>

            <Dialog open={openModal} onClose={toggleModal}>
                <EmailForm client={client} callbackEmailUpdate={updateEmail} email={selectedEmail} />
            </Dialog>
        </Box>
    );
}
