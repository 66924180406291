import { Box, Breadcrumbs, Paper } from '@mui/material';
import { Link } from 'react-router-dom';
import InvoiceForm from './InvoiceForm';

const Index = () => {
    return (
        <>
            <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
                <Link to={'/invoices'}>Invoices</Link>
                <Box>New invoice</Box>
            </Breadcrumbs>
            <Paper variant='elevation' sx={{ p: 2, mt: 2 }}>
                <InvoiceForm />
            </Paper>
        </>
    );
};

export default Index;
