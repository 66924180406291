import {yupResolver} from '@hookform/resolvers/yup';
import {HelpOutlined, Save} from '@mui/icons-material';
import {Box, FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, TextField, Tooltip, Typography,} from '@mui/material';
import {useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {setLocale} from 'yup';
import {BusinessClient, BusinessEntityType, Client, createBusiness, CreateBusinessFields} from '../../apis/clients';
import AddressFields from '../../components/AddressFields';
import {LoadingButton} from '../../components/LoadingButton';

setLocale({
    number: {
        min: 'Must be greater than ${min}',
        max: 'Must be less than ${max}',
    },
});

const mobileRegex = /(^(\+?64|0)2[0-2,6-9](\s|-|)\d{3,4}(\s|-|)\d{3,4}$)/;

const schema = yup.object({
    email: yup.string().email('Valid email required').optional(),
    suburb: yup.string().optional(),
    cityTown: yup.string().optional(),
    postcode: yup.string().optional(),
    addressLine1: yup.string().required('Address line 1 required'),
    addressLine2: yup.string().optional(),
    mobileNumber: yup.string().required('Mobile number required').matches(mobileRegex, 'Valid mobile number required'),
    companiesOfficeNumber: yup.string().required('Companies office number required'),
    businessNumber: yup.string().required('Business number required'),
    entityType: yup.mixed<BusinessEntityType>().oneOf(Object.values(BusinessEntityType)).required(),
    businessName: yup.string().required('Business name required'),
    tradingName: yup.string().optional(),
    authorizedIndividual: yup.object({
        email: yup.string().email('Valid email required').optional(),
        firstName: yup.string().required('Authorized first name required'),
        lastName: yup.string().required('Authorized last name required'),
        mobileNumber: yup
            .string()
            .required('Authorized mobile number required')
            .matches(mobileRegex, 'Valid mobile number required'),
    }),
});

interface CreateClientProps {
    callback?: (client: Client) => void;
    client?: BusinessClient;
    saveCounter?: number;
    handleSave?: (fields: CreateBusinessFields) => void;
    inputSize?: 'small' | 'medium';
}

export default ({callback, client, saveCounter, handleSave, inputSize}: CreateClientProps) => {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);

    const {
        handleSubmit,
        control,
        formState: {errors},
        setValue,
        clearErrors,
    } = useForm<CreateBusinessFields>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (saveCounter) {
            handleSubmit(onSubmit)();
        }
    }, [saveCounter]);

    const onSubmit: SubmitHandler<CreateBusinessFields> = (data) => {
        setSubmitting(true);

        if (handleSave) {
            handleSave(data);
            return;
        }

        createBusiness(data, client?.uuid)
            .then((clientResponse) => {
                if (callback) {
                    callback(clientResponse);
                } else {
                    navigate('/clients/' + clientResponse.uuid);
                }
            })
            .finally(() => setSubmitting(false));
    };

    return (
        <Box sx={{height: '100%'}}>
            <form
                autoComplete='off'
                onSubmit={handleSubmit(onSubmit)}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='businessName'>Business name</FormLabel>
                            <Controller
                                name='businessName'
                                control={control}
                                defaultValue={client?.businessName}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='businessName'
                                        autoComplete='no'
                                        error={errors.businessName != undefined}
                                        helperText={errors.businessName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='tradingName'>Trading name</FormLabel>
                            <Controller
                                name='tradingName'
                                control={control}
                                defaultValue={client?.tradingName}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='tradingName'
                                        autoComplete='no'
                                        error={errors.tradingName != undefined}
                                        helperText={errors.tradingName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='entityType-label'>Business type</FormLabel>
                            <Controller
                                name='entityType'
                                control={control}
                                defaultValue={client?.entityType}
                                render={({field}) => (
                                    <Select
                                        {...field}
                                        id='entityType'
                                        fullWidth
                                        error={errors.entityType != undefined}
                                        size={inputSize}
                                    >
                                        <MenuItem value={BusinessEntityType.REGISTERED_COMPANY}>
                                            Registered company
                                        </MenuItem>
                                        <MenuItem value={BusinessEntityType.SOLE_TRADER}>Sole trader</MenuItem>
                                        <MenuItem value={BusinessEntityType.LIMITED_LIABILITY}>
                                            Limited liability
                                        </MenuItem>
                                    </Select>
                                )}
                            />
                            <FormHelperText>{errors.entityType?.message}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item md={6} sm={12}></Grid>

                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='companiesOfficeNumber'>Companies office number</FormLabel>
                            <Controller
                                name='companiesOfficeNumber'
                                control={control}
                                defaultValue={client?.companiesOfficeNumber}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='companiesOfficeNumber'
                                        autoComplete='no'
                                        error={errors.companiesOfficeNumber != undefined}
                                        helperText={errors.companiesOfficeNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='businessNumber'>Business number</FormLabel>
                            <Controller
                                name='businessNumber'
                                control={control}
                                defaultValue={client?.businessNumber}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='businessNumber'
                                        autoComplete='no'
                                        error={errors.businessNumber != undefined}
                                        helperText={errors.businessNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Controller
                                name='email'
                                control={control}
                                defaultValue={client?.contactDetails.email?.address}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        id='email'
                                        size={inputSize}
                                        fullWidth
                                        autoComplete='no'
                                        error={errors.email != undefined}
                                        helperText={errors.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='mobileNumber'>Mobile number</FormLabel>
                            <Controller
                                name='mobileNumber'
                                control={control}
                                defaultValue={client?.contactDetails.preferredPhoneNumber.number}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        id='mobileNumber'
                                        size={inputSize}
                                        fullWidth
                                        type='tel'
                                        autoComplete='no'
                                        error={errors.mobileNumber !== undefined}
                                        helperText={errors.mobileNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <AddressFields
                            errors={errors}
                            control={control}
                            setValue={setValue}
                            address={client?.contactDetails.preferredAddress}
                            clearErrors={clearErrors}
                            inputSize={inputSize}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            Authorized Individual:
                            <Tooltip
                                title='Authorized on behalf of business to enter into a loan agreement'
                                sx={{ml: 1}}
                            >
                                <IconButton>
                                    <HelpOutlined fontSize='small' style={{fontSize: '16px'}} />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Grid>

                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.firstName'>First name</FormLabel>
                            <Controller
                                name='authorizedIndividual.firstName'
                                control={control}
                                defaultValue={client?.authorizedIndividual.personalDetails.givenName}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='authorizedIndividual.firstName'
                                        autoComplete='no'
                                        error={errors.authorizedIndividual?.firstName !== undefined}
                                        helperText={errors.authorizedIndividual?.firstName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.lastName'>Last name</FormLabel>
                            <Controller
                                name='authorizedIndividual.lastName'
                                control={control}
                                defaultValue={client?.authorizedIndividual.personalDetails.surname}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='authorizedIndividual.lastName'
                                        autoComplete='no'
                                        error={errors.authorizedIndividual?.lastName !== undefined}
                                        helperText={errors.authorizedIndividual?.lastName?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor='authorizedIndividual.email'>Email</FormLabel>
                            <Controller
                                name='authorizedIndividual.email'
                                control={control}
                                defaultValue={client?.authorizedIndividual.contactDetails.email?.address}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='authorizedIndividual.email'
                                        fullWidth
                                        autoComplete='no'
                                        error={errors.authorizedIndividual?.email != undefined}
                                        helperText={errors.authorizedIndividual?.email?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth required>
                            <FormLabel htmlFor='authorizedIndividual.mobileNumber'>Mobile number</FormLabel>
                            <Controller
                                name='authorizedIndividual.mobileNumber'
                                control={control}
                                defaultValue={client?.authorizedIndividual.contactDetails.preferredPhoneNumber.number}
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        size={inputSize}
                                        id='authorizedIndividual.mobileNumber'
                                        fullWidth
                                        type='tel'
                                        autoComplete='no'
                                        error={errors.authorizedIndividual?.mobileNumber !== undefined}
                                        helperText={errors.authorizedIndividual?.mobileNumber?.message}
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item md={6} sm={12}></Grid>

                    {!handleSave && (
                        <Grid item md={6} sm={12} pb={2}>
                            <LoadingButton
                                data-testid='saveClient'
                                type='submit'
                                variant='contained'
                                fullWidth
                                size={'large'}
                                startIcon={<Save />}
                                loading={submitting}
                            >
                                Save client
                            </LoadingButton>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Box>
    );
};
