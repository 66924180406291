import axios from 'axios';
import { Invoice } from './invoice';
import { SearchResult } from './common';

export const fetchClientInvoices = async (clientUuid?: string): Promise<PolicyPage> => {
    return await axios
        .get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/insureds/' + clientUuid + '/policies')
        .then(({ data }) => data);
};

export type PolicyPage = SearchResult<Invoice>;
