import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Drawer, ListItemText, Menu, MenuItem } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useState } from 'react';
import { ActivityVariation } from '../../../apis/enquiry';
import ContactForm from './enquiryActionForms/ContactForm';
import NoteForm from './enquiryActionForms/NoteForm';

enum ActivityForm {
    NOTE = 'NOTE',
    CONTACT = 'CONTACT',
}

type Props = {
    enquiryIdentifier?: string;
    enquiryContactDateTime?: string;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
};

export default function EnquiryActivityAction({
    enquiryIdentifier,
    enquiryContactDateTime,
    addNewActivityCallback,
}: Readonly<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [formToggled, setFormToggled] = useState<ActivityForm>(ActivityForm.NOTE);

    const actionMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    const closeDrawer = () => {
        setOpenDrawer(false);
    };

    return (
        <Box>
            <Button
                sx={buttonStyle}
                id='enquiry-activity-action'
                onClick={handleOpenMenu}
                variant='outlined'
                color='info'
                endIcon={actionMenuOpen ? <ExpandLess /> : <ExpandMore />}
            >
                Log activity
            </Button>

            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{ paper: { sx: { minWidth: 280 } } }}
            >
                <MenuItem
                    onClick={() => {
                        setFormToggled(ActivityForm.NOTE);
                        closeActionMenu();
                        setOpenDrawer(true);
                    }}
                >
                    <ListItemText>Add an internal note</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setFormToggled(ActivityForm.CONTACT);
                        closeActionMenu();
                        setOpenDrawer(true);
                    }}
                >
                    <ListItemText>Add contact</ListItemText>
                </MenuItem>
            </Menu>

            <Drawer
                anchor='right'
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                PaperProps={{
                    sx: { width: { xs: '80%', sm: '50%' }, maxWidth: '1200px' },
                }}
            >
                {activityActionForm(
                    enquiryIdentifier as string,
                    enquiryContactDateTime as string,
                    formToggled,
                    closeDrawer,
                    addNewActivityCallback
                )}
            </Drawer>
        </Box>
    );
}

const activityActionForm = (
    enquiryIdentifier: string,
    enquiryContactDate: string,
    formToggled: ActivityForm,
    closeDrawer: () => void,
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void
) => {
    switch (formToggled) {
        case ActivityForm.NOTE:
            return (
                <NoteForm
                    enquiryIdentifier={enquiryIdentifier}
                    closeDrawer={closeDrawer}
                    addNewActivityCallback={addNewActivityCallback}
                />
            );
        case ActivityForm.CONTACT:
            return (
                <ContactForm
                    enquiryIdentifier={enquiryIdentifier}
                    enquiryContactDate={enquiryContactDate}
                    closeDrawer={closeDrawer}
                    addNewActivityCallback={addNewActivityCallback}
                />
            );
    }
};

const buttonStyle = { width: 160, justifyContent: 'space-evenly', backgroundColor: grey[50] };
