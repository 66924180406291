import { CheckCircle } from '@mui/icons-material';
import { Backdrop, CircularProgress, Collapse, Divider, Paper, useTheme } from '@mui/material';
import { common } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { ClientLeadResult, matchClientLead } from '../../../apis/clientLead';
import {
    BaseClient,
    CreateBusinessFields,
    CreateIndividualFields,
    CreateInsuredRequest,
    CreateTrustFields,
    createInsured,
} from '../../../apis/clients';
import {
    FetchState,
    FetchStateType,
    isError,
    isLoading,
    isSuccess,
    loadingState,
    pendingState,
} from '../../../hooks/useFetch';
import { BaseInvoiceFormFields, getBaseInvoiceRequest } from '../../CreateInvoice/invoiceValidation';
import ClientLeadDetails from './ClientLeadDetails';
import ClientLeadMatcher from './ClientLeadMatcher';

type Props = { result: ClientLeadResult; removeMatchedLead: (uuid: string) => void };

export default function ClientLeadCell({ result, removeMatchedLead }: Readonly<Props>) {
    const theme = useTheme();
    const [matchState, setMatchState] = useState<FetchState<null>>(pendingState);
    const [editMode, setEditMode] = useState(false);
    const [invoiceFields, setInvoiceFields] = useState<BaseInvoiceFormFields>();
    const [client, setClient] = useState<
        CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient
    >();

    useEffect(() => {
        setClient(result.client);
    }, [result.client]);

    const handleSave = async (
        invoiceFields: BaseInvoiceFormFields,
        client: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient
    ) => {
        setInvoiceFields(invoiceFields);
        setClient(client);
        setEditMode(false);
        setMatchState(loadingState);
        let insuredIdentifier = (client as BaseClient).uuid;
        const invoice = getBaseInvoiceRequest(invoiceFields);

        doSave(async () => {
            if (!insuredIdentifier) {
                const newInsured = await createInsured(client as CreateInsuredRequest, result.invoice.clientIdentifier);
                insuredIdentifier = newInsured.uuid;
            }

            await matchClientLead(result.clientLead.uuid, { insuredIdentifier, invoice });
        });
    };

    const doSave = async (save: () => Promise<void>) => {
        try {
            await save();

            setMatchState({ type: FetchStateType.SUCCESS, value: null });
            removeMatchedLead(result.clientLead.uuid);
        } catch {
            setEditMode(true);
            setMatchState({ type: FetchStateType.ERROR, error: new Error('Something went wrong, please try again.') });
        }
    };

    return (
        <Paper variant='outlined' sx={{ p: 2, position: 'relative' }}>
            <Backdrop
                sx={{ position: 'absolute', borderRadius: `${theme.shape.borderRadius}px` }}
                open={isLoading(matchState) || isSuccess(matchState)}
            >
                {isLoading(matchState) && <CircularProgress data-testid='loading' sx={{ color: common.white }} />}
                {isSuccess(matchState) && (
                    <CheckCircle data-testid='matched' fontSize='large' sx={{ color: common.white }} />
                )}
            </Backdrop>
            <ClientLeadDetails
                result={result}
                editMode={editMode}
                setEditMode={() => setEditMode(true)}
                client={client}
                invoiceFields={invoiceFields}
                matchState={matchState}
            />
            <Collapse in={editMode}>
                <Divider sx={{ my: 2 }} />
                <ClientLeadMatcher
                    result={result}
                    cancel={() => setEditMode(false)}
                    handleSave={handleSave}
                    error={isError(matchState) ? matchState.error.message : undefined}
                />
            </Collapse>
        </Paper>
    );
}
