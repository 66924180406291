import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    ActivityType,
    ActivityVariation,
    Contact,
    EnquiryDetailedSummary,
    EnquiryStatus,
    fetchEnquiryDetails,
} from '../../apis/enquiry';
import { FetchWrapper } from '../../components/FetchWrapper';
import { FetchState, FetchStateType } from '../../hooks/useFetch';
import EnquiryActivitySection from './EnquiryActivitySection';
import EnquiryDetailSection from './EnquiryDetailSection';
import HeaderSection from './EnquiryHeaderSection';
import EnquirySubHeaderSection from './EnquirySubHeaderSection';

export default function EnquiryDetails() {
    const { uuid } = useParams<{ uuid?: string }>();
    const [loadState, setLoadState] = useState(FetchStateType.LOADING);
    const [enquiryDetails, setEnquiryDetails] = useState<EnquiryDetailedSummary>();
    const [enquiryActivities, setEnquiryActivities] = useState<ActivityVariation[]>([]);

    useEffect(() => {
        setLoadState(FetchStateType.LOADING);

        fetchEnquiryDetails(uuid as string)
            .then((res) => {
                setEnquiryDetails(res);
                setLoadState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setLoadState(FetchStateType.ERROR);
            });
    }, []);

    const getCorrectDateByActivityType = (enquiryActivity: ActivityVariation) => {
        return enquiryActivity.activityType === ActivityType.CONTACT
            ? (enquiryActivity as Contact).contactDateTime
            : enquiryActivity.createdDate;
    };

    const findInsertIndex = (enquiryActivities: ActivityVariation[], newActivity: ActivityVariation) => {
        let start = 0;
        let end = enquiryActivities.length - 1;

        const newActivityDate = getCorrectDateByActivityType(newActivity);

        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            const existingItemDate = getCorrectDateByActivityType(enquiryActivities[mid]);

            if (moment(existingItemDate).isAfter(moment(newActivityDate))) {
                start = mid + 1;
            } else {
                end = mid - 1;
            }
        }
        return start;
    };

    const addNewActivityCallback = (newActivity: ActivityVariation) => {
        setEnquiryActivities((prevList) => {
            const addToIndex = findInsertIndex(prevList, newActivity);
            if (addToIndex === prevList.length) {
                return prevList;
            }

            const updatedList = [...prevList.slice(0, addToIndex), newActivity, ...prevList.slice(addToIndex)];
            return updatedList;
        });
    };

    const enquiryStatusUpdateCallback = (newEnquiryStatus: EnquiryStatus) => {
        setEnquiryDetails({
            ...enquiryDetails,
            status: newEnquiryStatus,
        } as EnquiryDetailedSummary);
    };

    return (
        <Box>
            <FetchWrapper state={{ type: loadState } as FetchState<void>} SuccessComponent={() => null} />
            {loadState === FetchStateType.SUCCESS && (
                <Box sx={{ my: 2, width: { xs: '100%', sm: '70%' } }}>
                    <HeaderSection
                        enquiryType={enquiryDetails?.enquiryDetail.enquiryType}
                        clientName={enquiryDetails?.enquiryDetail.clientName}
                    />
                    <EnquirySubHeaderSection
                        uuid={enquiryDetails?.uuid}
                        status={enquiryDetails?.status}
                        latestAssigneeIdentifier={enquiryDetails?.latestAssigneeIdentifier}
                        latestAssigneeName={enquiryDetails?.latestAssigneeName}
                        addNewActivityCallback={addNewActivityCallback}
                        enquiryStatusUpdateCallback={enquiryStatusUpdateCallback}
                    />
                    <EnquiryDetailSection enquiryDetail={enquiryDetails?.enquiryDetail} />
                    <EnquiryActivitySection
                        uuid={enquiryDetails?.uuid}
                        enquiryContactDateTime={enquiryDetails?.contactDateTime}
                        enquiryActivities={enquiryActivities}
                        setEnquiryActivities={setEnquiryActivities}
                        addNewActivityCallback={addNewActivityCallback}
                    />
                </Box>
            )}
        </Box>
    );
}
