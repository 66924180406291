import { Avatar, Box, Paper, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { Policy } from '../../../apis/invoice';
import PolicyStatusChip from '../../../components/PolicyStatusChip';
import { useMemo } from 'react';
import { upperCase } from 'lodash';
import { NavigateNext } from '@mui/icons-material';
import { Link } from 'react-router-dom';

type Props = {
    policy: Policy;
    sellerProductLogo?: string;
    uuid: string;
};

export default function PolicyDetail({ policy, sellerProductLogo, uuid }: Readonly<Props>) {
    const initials = useMemo(() => {
        const parts = policy.currentPolicyVersion.productCode.split(' ');
        parts.length = Math.min(parts.length, 2);
        return parts.map((p) => upperCase(p.charAt(0)));
    }, [policy]);

    return (
        <Paper
            variant='flat'
            sx={{
                alignItems: 'center',
                border: '1px solid',
                borderColor: grey[300],
                display: 'flex',
                gap: 2,
                justifyContent: 'space-between',
                p: 3,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {sellerProductLogo ? (
                    <img src={sellerProductLogo} style={{ width: '60px', height: '60px' }} alt='Seller Product logo' />
                ) : (
                    <Avatar sx={{ bgcolor: blue['200'], width: '60px', height: '60px' }}>{initials}</Avatar>
                )}
                <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Typography variant='h6'>{policy.currentPolicyVersion.productName}</Typography>
                        <PolicyStatusChip status={policy.currentPolicyVersion.status} />
                    </Box>
                    <Typography variant='body2'>{policy.number}</Typography>
                </Box>
            </Box>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
                <Link to={'/invoices/' + uuid} style={{ verticalAlign: 'middle' }}>
                    <NavigateNext color='primary' />
                </Link>
            </Box>
        </Paper>
    );
}
