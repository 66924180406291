import { Cached, Edit, MailOutline, Person, Phone, QuestionMark, SmsOutlined } from '@mui/icons-material';
import { Box, Button, Palette, Paper, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { ActivityType, ActivityVariation, Contact, ContactType, fetchEnquiryActivities } from '../../../apis/enquiry';
import { FetchWrapper } from '../../../components/FetchWrapper';
import Timeline, { TimelineObject } from '../../../components/Timeline';
import { FetchState, FetchStateType } from '../../../hooks/useFetch';
import ActivityItemComponent from './ActivityItemComponent';

type Props = {
    enquiryIdentifier: string;
    enquiryContactDateTime: string;
    enquiryActivities: ActivityVariation[];
    setEnquiryActivities: (newActivities: ActivityVariation[]) => void;
};

const defaultPageSize = 20;

export default function Activities({
    enquiryIdentifier,
    enquiryContactDateTime,
    enquiryActivities,
    setEnquiryActivities,
}: Readonly<Props>) {
    const theme = useTheme();
    const [page, setPage] = useState(0);
    const [loadState, setLoadState] = useState(FetchStateType.LOADING);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        setLoadState(FetchStateType.LOADING);
        fetchEnquiryActivities(enquiryIdentifier, {
            page: page,
            pageSize: defaultPageSize,
        })
            .then((res) => {
                if (res.totalPages === page + 1 || (res.records.length === 0 && res.page === 0)) {
                    const initialActivity = {
                        uuid: 'INITIAL',
                        activityType: ActivityType.INITIAL,
                        createdDate: enquiryContactDateTime,
                    } as ActivityVariation;

                    res.records.push(initialActivity);
                }

                if (page === 0) {
                    setEnquiryActivities(res.records);
                } else {
                    const newRes = res.records.filter(
                        (record) => !enquiryActivities.some((activity) => activity.uuid === record.uuid)
                    ); // filter already returned values
                    setEnquiryActivities([...enquiryActivities, ...newRes]);
                }
                setTotalRecords(res.totalRecords);
                setLoadState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setLoadState(FetchStateType.ERROR);
            });
    }, [enquiryIdentifier, page]);

    const getLoadMore = () => {
        if (loadState === FetchStateType.SUCCESS && totalRecords > enquiryActivities.length) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 4 }}>
                    <Button variant='contained' onClick={() => setPage(page + 1)}>
                        Load more
                    </Button>
                </Box>
            );
        }

        return null;
    };

    return (
        <Box>
            <Paper variant='flat' sx={{ py: 3, px: 3 }}>
                {(loadState === FetchStateType.SUCCESS || page !== 0) && (
                    <Timeline
                        timelineItems={prepareTimelineBody(theme.palette, enquiryActivities)}
                        emptyItemMessage='Actions made for this enquiry will appear here.'
                        DayContentComponent={ActivityItemComponent}
                    />
                )}
                <FetchWrapper state={{ type: loadState } as FetchState<void>} SuccessComponent={() => null} />
                {getLoadMore()}
            </Paper>
        </Box>
    );
}

const prepareTimelineBody = (
    palette: Palette,
    enquiryActivities: ActivityVariation[]
): TimelineObject<ActivityVariation>[] => {
    return enquiryActivities.map((activity) => {
        return {
            sortableDateTime:
                activity.activityType === ActivityType.CONTACT
                    ? (activity as Contact).contactDateTime
                    : activity.createdDate,
            iconColour: getIconColour(palette, activity),
            IconComponent: getActivityIcon(activity),
            displayObj: activity,
        };
    });
};

const getIconColour = (palette: Palette, activity: ActivityVariation) => {
    switch (activity.activityType) {
        case ActivityType.INITIAL:
            return palette.primary.main;
        case ActivityType.CONTACT: {
            const contactType = (activity as Contact).contactType;
            switch (contactType) {
                case ContactType.EMAIL:
                    return palette.primary.main;
                case ContactType.SMS:
                    return palette.success.main;
                default:
                    return palette.navy['80'];
            }
        }
        default:
            return grey[600];
    }
};

const getActivityIcon = (activity: ActivityVariation) => {
    switch (activity.activityType) {
        case ActivityType.INITIAL:
            return MailOutline;
        case ActivityType.CONTACT: {
            const contactType = (activity as Contact).contactType;
            switch (contactType) {
                case ContactType.EMAIL:
                    return MailOutline;
                case ContactType.SMS:
                    return SmsOutlined;
                default:
                    return Phone;
            }
        }
        case ActivityType.ASSIGNMENT:
            return Person;
        case ActivityType.STATUS_UPDATE:
            return Cached;
        case ActivityType.NOTE:
            return Edit;
        default:
            return QuestionMark;
    }
};
