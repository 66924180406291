import { Box, Chip, Divider, Paper, Typography } from '@mui/material';
import moment from 'moment';
import { ChargeType, Invoice, InvoiceDetailsResponse, InvoiceStatus, LoanSubStatus } from '../../../apis/invoice';
import { DATE_FRIENDLY } from '../../../util/dateUtils';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
};

export default function PremiumDetailsCard({ invoiceDetails }: Readonly<Props>) {
    const { invoice } = invoiceDetails;
    const { setupFee, totalAmount } = getPremiumFees(invoice);
    const paymentStatus = getInvoicePaymentStatus(invoiceDetails);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Premium summary
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box>
                        {totalAmount === invoice.totalPremiums ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant='subtitle2'>Total premiums</Typography>
                                <Typography variant='subtitle2'>{currencyFormat.format(totalAmount)}</Typography>
                            </Box>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='body2'>Premiums</Typography>
                                    <Typography variant='body2'>
                                        {currencyFormat.format(invoice.totalPremiums)}
                                    </Typography>
                                </Box>
                                {setupFee > 0 && (
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant='body2'>Setup fee</Typography>
                                        <Typography variant='body2'>{currencyFormat.format(setupFee)}</Typography>
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant='subtitle2'>Total</Typography>
                                    <Typography variant='subtitle2'>{currencyFormat.format(totalAmount)}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    {paymentStatus && (
                        <>
                            <Divider />
                            <Box>{paymentStatus}</Box>
                        </>
                    )}
                </Box>
            </Paper>
        </Box>
    );
}

type PremiumFees = {
    setupFee: number;
    totalAmount: number;
};

const getPremiumFees = (invoice: Invoice): PremiumFees => {
    if (invoice.status === InvoiceStatus.CANCELLED) {
        return {
            setupFee: 0,
            totalAmount: invoice.totalPremiums,
        };
    }

    const setupFee = invoice?.term?.feeCharges?.find((charge) => charge.type === ChargeType.SETUP_FEE)?.amount || 0;

    return {
        setupFee,
        totalAmount: invoice.totalPremiums + setupFee,
    };
};

const getInvoicePaymentStatus = (invoiceDetails: InvoiceDetailsResponse): JSX.Element | null => {
    const isDeferredStart =
        invoiceDetails.invoice.term && moment(invoiceDetails.invoice.term.firstPaymentDate).isAfter(moment());

    if (invoiceDetails.invoice.status === InvoiceStatus.CANCELLED) {
        return <Chip color='error' label='Voided' />;
    }

    if (invoiceDetails.loan == null || isDeferredStart) {
        return <Chip color='error' label={`Due on ${moment(invoiceDetails.invoice.dueDate).format(DATE_FRIENDLY)}`} />;
    }

    if (invoiceDetails.loan?.subStatus === LoanSubStatus.PARTIALLY_PAID) {
        return <Chip color='warning' label='Partially paid' />;
    }

    return null;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
