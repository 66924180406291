import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { DATE_VERBOSE } from '../../util/dateUtils';
import DayLine from './DayLine';

export type TimelineObject<T> = {
    displayObj: T;
    sortableDateTime: string;
    iconColour: string;
    IconComponent: React.FC;
};

type DayContentComponentProps<T> = {
    bodyItem: T;
};

type Props<T> = {
    timelineItems: TimelineObject<T>[];
    emptyItemMessage: string;
    DayContentComponent: ({ bodyItem }: DayContentComponentProps<T>) => JSX.Element;
};

export default function Timeline<T>({ timelineItems, emptyItemMessage, DayContentComponent }: Readonly<Props<T>>) {
    const groupedObjects = groupTimelines(timelineItems);

    if (groupedObjects == null) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center', p: 3, pb: 4 }}>
                <Typography variant='body2'>
                    No records found.
                    <br />
                    {emptyItemMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <Box mb={4}>
            {Object.entries(groupedObjects).map(([key, lineItems], index) => {
                return (
                    <Box key={key}>
                        <DayLine
                            dateTime={key}
                            lineItems={lineItems}
                            hasTail={index < Object.keys(groupedObjects).length - 1}
                            DayContentComponent={DayContentComponent}
                        />
                    </Box>
                );
            })}
        </Box>
    );
}

type GroupedTimelineObj<T> = Record<string, TimelineObject<T>[]>;

const groupTimelines = <T,>(timelineItems: TimelineObject<T>[]) => {
    if (timelineItems == null || timelineItems.length === 0) {
        return null;
    }

    const groupedTimelineObjs: GroupedTimelineObj<T> = {};
    timelineItems.forEach((timelineItem: TimelineObject<T>) => {
        const submittedAt = getSubmittedKey(timelineItem.sortableDateTime);
        if (!(submittedAt in groupedTimelineObjs)) {
            groupedTimelineObjs[submittedAt] = [];
        }
        groupedTimelineObjs[submittedAt].push(timelineItem);
    });
    return groupedTimelineObjs;
};

const getSubmittedKey = (submittedAtUtc: string) => {
    const localDate = moment.utc(submittedAtUtc).local();
    if (moment().isSame(localDate, 'day')) {
        return `Today - ${localDate.format(DATE_VERBOSE)}`;
    }

    return localDate.format(DATE_VERBOSE);
};
