import { Alert, Box, Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ClientLeadResult, isBankTransfer } from '../../../apis/clientLead';
import { BaseClient, CreateBusinessFields, CreateIndividualFields, CreateTrustFields } from '../../../apis/clients';
import { BaseInvoiceFormFields } from '../../CreateInvoice/invoiceValidation';
import InvoiceMatcher from '../Invoice/InvoiceMatcher';
import ClientMatcher from '../MatchClient/ClientMatcher';
import BankTransferPaymentInformation from './BankTransferPaymentInformation';

type Props = {
    result: ClientLeadResult;
    cancel: () => void;
    handleSave: (
        invoice: BaseInvoiceFormFields,
        client: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient
    ) => void;
    error?: string;
};

export default function ClientLeadMatcher({ result, cancel, handleSave, error }: Readonly<Props>) {
    const [saveCounter, setSaveCounter] = useState(0);
    const [invoiceFields, setInvoiceFields] = useState<BaseInvoiceFormFields | null>(null);
    const [client, setClient] = useState<
        CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient | null
    >(null);

    useEffect(() => {
        if (invoiceFields && client) {
            handleSave(invoiceFields, client);
        }
    }, [invoiceFields, client]);

    const onSubmit = () => {
        setInvoiceFields(null);
        setClient(null);
        setSaveCounter(saveCounter + 1);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            {isBankTransfer(result.paymentMethod) && (
                <BankTransferPaymentInformation bankTransferPaymentMethod={result.paymentMethod} />
            )}
            <InvoiceMatcher invoice={result.invoice} saveCounter={saveCounter} save={setInvoiceFields} />
            <ClientMatcher result={result} saveCounter={saveCounter} save={setClient} />
            {error && (
                <Grid item xs={12}>
                    <Alert severity='error'>{error}</Alert>
                </Grid>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button size='small' onClick={cancel}>
                    Cancel
                </Button>
                <Button size='small' variant='contained' onClick={onSubmit}>
                    Save
                </Button>
            </Box>
        </Box>
    );
}
