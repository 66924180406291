import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ClientLeadResult } from '../../../apis/clientLead';
import {
    BaseClient,
    Client,
    CreateBusinessFields,
    CreateIndividualFields,
    CreateTrustFields,
} from '../../../apis/clients';
import CreateClient from './CreateClient';
import ExistingClient from './ExistingClient';
import SearchClients from './SearchClients';

type Props = {
    result: ClientLeadResult;
    saveCounter?: number;
    save: (fields: CreateIndividualFields | CreateBusinessFields | CreateTrustFields | BaseClient) => void;
};

enum Action {
    USE_SUGGESTED,
    USE_LINKED,
    CREATE,
    MATCH,
}

export default function ClientMatcher({ result, saveCounter, save }: Readonly<Props>) {
    const [saveClientCounter, setSaveClientCounter] = useState<number>(0);
    const [error, setError] = useState<string>();
    const [action, setAction] = useState(result.suggestedInsured?.uuid ? Action.USE_SUGGESTED : Action.MATCH);
    const [selectedClient, setSelectedClient] = useState(result.suggestedInsured);

    const handleSelectClient = (client: Client) => {
        setSelectedClient(client);
        setAction(Action.USE_SUGGESTED);
    };

    useEffect(() => {
        if (result.client?.sellerConfirmationDate) {
            setSelectedClient(result.client);
            setAction(Action.USE_LINKED);
        }
    }, [result.client]);

    useEffect(() => {
        if (!saveCounter) {
            return;
        }

        if (action === Action.CREATE) {
            setSaveClientCounter(saveClientCounter + 1);
            return;
        }

        if (selectedClient) {
            save(selectedClient);
            return;
        }

        setError('You must select a client');
    }, [saveCounter]);

    const getAction = () => {
        if ((action === Action.USE_SUGGESTED || action === Action.USE_LINKED) && selectedClient) {
            return (
                <ExistingClient
                    client={selectedClient}
                    change={action === Action.USE_SUGGESTED ? () => setAction(Action.MATCH) : undefined}
                    isSuggested={selectedClient.uuid === result.suggestedInsured?.uuid}
                    isLinked={action == Action.USE_LINKED}
                />
            );
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <RadioGroup value={action} row>
                    <FormControlLabel
                        value={Action.CREATE}
                        control={<Radio />}
                        label='Create'
                        onChange={() => {
                            setSelectedClient(undefined);
                            setAction(Action.CREATE);
                        }}
                    />
                    <FormControlLabel
                        value={Action.MATCH}
                        control={<Radio />}
                        label='Match'
                        onChange={() => {
                            setSelectedClient(undefined);
                            setAction(Action.MATCH);
                        }}
                    />
                </RadioGroup>
                {action === Action.MATCH && <SearchClients selectClient={handleSelectClient} error={error} />}
                {action === Action.CREATE && (
                    <CreateClient clientLead={result.clientLead} saveCounter={saveClientCounter} save={save} />
                )}
            </Box>
        );
    };

    return (
        <Box>
            <Typography variant='subtitle2' mb={2}>
                2. Client details
            </Typography>
            {getAction()}
        </Box>
    );
}
