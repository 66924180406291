import { Box } from '@mui/material';
import { Client } from '../../../apis/clients';
import { ContactDetails } from '../../../types/Types';
import Emails from './Emails';
import PhoneNumbers from './PhoneNumbers';
import Addresses from './Addresses';

interface ContactDetailsProps {
    client: Client;
    updateClientContactDetails: (contactDetails: ContactDetails) => void;
}

export default function ContactDetailsSection({ client, updateClientContactDetails }: Readonly<ContactDetailsProps>) {
    return (
        <Box sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Emails
                emails={client.contactDetails.emails}
                client={client}
                updateClientContactDetails={updateClientContactDetails}
            />
            <PhoneNumbers
                phoneNumbers={client.contactDetails.phoneNumbers}
                client={client}
                updateClientContactDetails={updateClientContactDetails}
            />
            <Addresses
                addresses={client.contactDetails.addresses}
                client={client}
                updateClientContactDetails={updateClientContactDetails}
            />
        </Box>
    );
}
