import { FetchWrapper } from '../../../components/FetchWrapper';
import { useFetch } from '../../../hooks/useFetch';
import { fetchClientInvoices, PolicyPage } from '../../../apis/policies';
import PolicyDetail from './PolicyDetail';
import { Paper } from '@mui/material';
import NoRecords from '../../../components/NoRecords';

type CustomerInvoiceProps = {
    clientUuid?: string;
};

export default function Policies({ clientUuid }: CustomerInvoiceProps) {
    const state = useFetch(() => fetchClientInvoices(clientUuid), [clientUuid]);

    const SuccessComponent = ({ data }: { data: PolicyPage }) => {
        return (
            <>
                {!data.records || data.records.length === 0 ? (
                    <Paper variant='flat' sx={{ p: 2 }}>
                        <NoRecords />
                    </Paper>
                ) : (
                    data.records.map((invoice) => {
                        return invoice.portfolio.policies.map((policy) => {
                            return <PolicyDetail key={policy.uuid} policy={policy} uuid={invoice.uuid} />;
                        });
                    })
                )}
            </>
        );
    };

    return <FetchWrapper state={state} SuccessComponent={SuccessComponent} />;
}
