import { toLower, toUpper } from 'lodash';
import { SortOrder } from 'react-data-table-component';

export enum Direction {
    DESC = 'DESC',
    ASC = 'ASC',
}

export const defaultPageSize = 10;

export type SearchResult<T> = ListParams & {
    totalPages: number;
    totalRecords: number;
    records: T[];
};

export type ListParams = {
    page: number;
    pageSize: number;
};

export const assignDefaultListParams = <T extends ListParams>(params?: Partial<T>): T => {
    return {
        ...params,
        page: params?.page ?? 0,
        pageSize: params?.pageSize ?? defaultPageSize,
    } as T;
};

export const sortOrderToDirection = (sortOrder: SortOrder): Direction => {
    return toUpper(sortOrder) as Direction;
};

export const directionToSortOrder = (direction: Direction): SortOrder => {
    return toLower(direction) as SortOrder;
};
