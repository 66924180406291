import { AccessTime, CheckCircle, Error, Handshake, HelpOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Avatar, Box, Divider, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import moment from 'moment';
import {
    ExpectedPaymentStatus,
    InvoiceDetailsResponse,
    Loan,
    LoanSubStatus,
    PaymentFrequency,
} from '../../../apis/invoice';
import { DATE_COMPACT } from '../../../util/dateUtils';
import CancellationAlert from '../Cancellation/CancellationAlert';
import { calculateDaysDifference, getDaysDiffDesc } from '../common';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    loan: Loan;
};

export default function PaymentSummaryCard({ invoiceDetails, loan }: Readonly<Props>) {
    const { invoice } = invoiceDetails;

    const isDeferredStart = loan.firstPaymentDate != null && moment(loan.firstPaymentDate).isAfter(moment());
    const isFirstPaymentScheduled =
        isDeferredStart ||
        ((loan.isPaymentScheduledToday &&
            loan.firstPaymentDate != null &&
            moment(loan.firstPaymentDate).isSame(new Date(), 'day')) ??
            false);
    const paymentSummaryState = determinePaymentSummaryState(isFirstPaymentScheduled, loan);
    const paidUntilDateDays = loan.paidUntilDate ? calculateDaysDifference(loan.paidUntilDate) : 0;
    const isPaidInFull = invoice.term?.paymentFrequency === PaymentFrequency.IN_FULL;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Payment summary
            </Typography>
            <Paper variant='flat' sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {loan.cancellationRequest && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <CancellationAlert />
                        {loan.subStatus === LoanSubStatus.CANCELLED && loan.balance !== 0 && (
                            <>
                                <Box>
                                    <Typography variant='caption'>Closing balance</Typography>
                                    <Typography variant='body2'>
                                        {currencyFormat.format(loan.cancellationRequest.cancellationClosingBalance!)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: 'flex', mt: 1 }}>
                                    <Error color='error' />
                                    <Typography variant='caption' color='error' sx={{ ml: 1 }}>
                                        Total is {loan.balance > 0 ? 'underpaid' : 'overpaid'} by{' '}
                                        {currencyFormat.format(loan.balance)}
                                    </Typography>
                                </Box>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                            </>
                        )}
                    </Box>
                )}
                <Box>
                    <Box>{renderPaymentSummaryState(paymentSummaryState, loan)}</Box>

                    {isFirstPaymentScheduled ? (
                        <Box>
                            <Typography variant='caption'>
                                {isPaidInFull ? 'P' : 'First p'}ayment scheduled for
                            </Typography>
                            <Typography variant='body2'>
                                {moment(invoice.term.firstPaymentDate).format(DATE_COMPACT)} (
                                {getFirstPaymentDesc(invoice.term.firstPaymentDate)})
                            </Typography>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant='caption'>Policy paid until</Typography>
                            <Typography variant='body2'>
                                {moment(loan.paidUntilDate).format(DATE_COMPACT)} ({getDaysDiffDesc(paidUntilDateDays)})
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Paper>
        </Box>
    );
}

const calculateOverdueAmount = (loan: Loan) => {
    if (!loan.consolidatedExpectedPayments) {
        return 0;
    }
    const overdueAmount = loan.consolidatedExpectedPayments
        .filter(({ status }) => [ExpectedPaymentStatus.OVERDUE, ExpectedPaymentStatus.ARRANGEMENT].includes(status))
        .reduce((total, expectedPayment) => total + expectedPayment.amount - (expectedPayment.paidAmount ?? 0), 0);

    return overdueAmount;
};

const determinePaymentSummaryState = (isFirstPaymentScheduled: boolean, loan: Loan) => {
    if (isFirstPaymentScheduled) {
        return PaymentSummaryState.SCHEDULED;
    }

    const overdueAmount = calculateOverdueAmount(loan);
    if (overdueAmount > 0) {
        const inArrangement = loan.consolidatedExpectedPayments.some(
            ({ status }) => status === ExpectedPaymentStatus.ARRANGEMENT
        );
        return inArrangement ? PaymentSummaryState.IN_ARRANGEMENT : PaymentSummaryState.OVERDUE;
    }

    if (loan.balance === 0) {
        return PaymentSummaryState.PAID;
    }

    return PaymentSummaryState.UP_TO_DATE;
};

const renderPaymentSummaryState = (paymentSummaryState: PaymentSummaryState, loan: Loan) => {
    const overdueAlertMessage = buildOverdueAlertMessage(loan.daysPastDue);
    switch (paymentSummaryState) {
        case PaymentSummaryState.UP_TO_DATE:
            return (
                <Box sx={{ display: 'flex', gap: 1, direction: 'column', alignItems: 'center', mb: 2 }}>
                    <CheckCircle color='success' fontSize='medium' />
                    <Typography variant='body2'>Payments up to date</Typography>
                </Box>
            );
        case PaymentSummaryState.OVERDUE:
            return (
                <Alert severity='error' sx={{ mb: 2 }}>
                    <AlertTitle>{currencyFormat.format(calculateOverdueAmount(loan))} Overdue</AlertTitle>
                    {overdueAlertMessage}
                </Alert>
            );
        case PaymentSummaryState.IN_ARRANGEMENT:
            return (
                <Alert severity='warning' sx={{ mb: 2 }} icon={<Handshake />}>
                    <AlertTitle>{currencyFormat.format(calculateOverdueAmount(loan))} Overdue</AlertTitle>
                    In Arrangement
                    <Tooltip title='The client has committed to a payment arrangement which is currently on track.'>
                        <IconButton>
                            <HelpOutlined style={{ fontSize: '16px' }} />
                        </IconButton>
                    </Tooltip>
                </Alert>
            );
        case PaymentSummaryState.SCHEDULED:
            return (
                <Box sx={{ display: 'flex', gap: 1, direction: 'column', alignItems: 'center', mb: 2 }}>
                    <Avatar sx={{ backgroundColor: yellow[600], width: 24, height: 24 }}>
                        <AccessTime color='action' sx={{ width: 18, height: 18 }} />
                    </Avatar>
                    <Typography variant='body2'>Payment scheduled</Typography>
                </Box>
            );
        case PaymentSummaryState.PAID:
            return (
                <Box sx={{ display: 'flex', gap: 1, direction: 'column', alignItems: 'center', mb: 2 }}>
                    <CheckCircle color='success' fontSize='medium' />
                    <Typography variant='body2'>Paid</Typography>
                </Box>
            );
    }
};

const buildOverdueAlertMessage = (daysPastDue: number) => {
    if (daysPastDue === 0) {
        return 'Payment failed today';
    }

    return `${daysPastDue} ${daysPastDue === 1 ? 'day' : 'days'} past due`;
};

const getFirstPaymentDesc = (firstPaymentDate: string): string => {
    const today = moment();
    const diff = moment(firstPaymentDate).diff(today, 'days');
    return getDaysDiffDesc(diff, 'from now');
};

enum PaymentSummaryState {
    UP_TO_DATE = 'UP_TO_DATE',
    OVERDUE = 'OVERDUE',
    IN_ARRANGEMENT = 'IN_ARRANGEMENT',
    SCHEDULED = 'SCHEDULED',
    PAID = 'PAID',
}
const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
