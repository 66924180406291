import { Alert, Box, Divider, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import Communications from '../../components/Communications';
import { useAppSelector } from '../../store/reducer/Hooks';
import { Client } from '../../apis/clients';
import ClientDetailsCard from './ClientDetailsCard';
import Policies from './Policies';
import Notes from './Notes';
import ContactDetailsSection from './ContactDetails';
import { ContactDetails } from '../../types/Types';

type Props = {
    client: Client;
    updateClientContactDetails: (contactDetails: ContactDetails) => void;
    refreshClient: (client: Client) => void;
};

export default function ClientBreakdown({ client, updateClientContactDetails, refreshClient }: Readonly<Props>) {
    const {
        permissions: { invoiceAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);
    const [tab, setTab] = useState(0);

    const handleTabChange = (_e: SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Box display='block'>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    <Box>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            TabIndicatorProps={{ sx: { display: 'none' } }}
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap',
                                },
                            }}
                        >
                            <Tab label='Policies' />
                            <Tab label='Notes' />
                            <Tab label='Communications' />
                            <Tab label='Contact information' />
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            py: 4,
                            gap: 2,
                            flexDirection: 'column',
                            maxWidth: { sm: 'calc(100vw - 320px)', md: 'calc(100vw - 636px)' },
                        }}
                    >
                        {tab === 0 &&
                            (invoiceAllowed ? (
                                <Policies clientUuid={client.uuid} />
                            ) : (
                                <Alert severity='info'>
                                    Your account doesn't have permission to view invoices. If you need help, contact
                                    your account admin.
                                </Alert>
                            ))}

                        {tab === 1 && <Notes client={client} />}
                        {tab === 2 && <Communications insuredIdentifier={client.uuid} />}
                        {tab === 3 && (
                            <ContactDetailsSection
                                client={client}
                                updateClientContactDetails={updateClientContactDetails}
                            />
                        )}
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: 2, width: { md: 300 }, minWidth: 300, flexDirection: 'column' }}>
                    <ClientDetailsCard client={client} refreshClient={refreshClient} />
                </Box>
            </Box>
        </Box>
    );
}
