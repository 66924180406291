import { CalendarToday } from '@mui/icons-material';
import { Box } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../../util/dateUtils';

type Props = {
    enquiryStartDate: string;
    setEnquiryStartDate: (startDate: string) => void;
    enquiryEndDate: string;
    setEnquiryEndDate: (endDate: string) => void;
};

export default function EnquiryCreateDateSelect({
    enquiryStartDate,
    setEnquiryStartDate,
    enquiryEndDate,
    setEnquiryEndDate,
}: Readonly<Props>) {
    const handleEnquiryCreateStartDateChange = (startDateInput: Moment | null) => {
        if (startDateInput == null) {
            setEnquiryStartDate('');
            return;
        }
        setEnquiryStartDate(moment(startDateInput).format(DATE_SERVER_FORMAT));
    };

    const handleEnquiryCreateEndDateChange = (endDateInput: Moment | null) => {
        if (endDateInput == null) {
            setEnquiryEndDate('');
            return;
        }
        setEnquiryEndDate(moment(endDateInput).format(DATE_SERVER_FORMAT));
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                    label='Start date'
                    defaultValue={null}
                    onChange={handleEnquiryCreateStartDateChange}
                    sx={{ width: '50%' }}
                    slotProps={{
                        textField: {
                            id: 'startDate',
                            size: 'small',
                            InputLabelProps: {
                                style: {
                                    paddingTop: 4,
                                },
                            },
                        },
                    }}
                    slots={{
                        openPickerIcon: CalendarToday,
                    }}
                    format={DATE_COMPACT}
                    maxDate={moment(enquiryEndDate)}
                />

                <DatePicker
                    label='End date'
                    defaultValue={null}
                    onChange={handleEnquiryCreateEndDateChange}
                    sx={{ width: '50%' }}
                    slotProps={{
                        textField: {
                            id: 'endDate',
                            size: 'small',
                            InputLabelProps: {
                                style: {
                                    paddingTop: 4,
                                },
                            },
                        },
                    }}
                    slots={{
                        openPickerIcon: CalendarToday,
                    }}
                    format={DATE_COMPACT}
                    minDate={moment(enquiryStartDate)}
                />
            </LocalizationProvider>
        </Box>
    );
}
