import { ExpandLess, ExpandMore, Person, Search } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Button,
    InputAdornment,
    ListItemText,
    Menu,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { isError } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import {
    ActivityType,
    ActivityVariation,
    Assignment,
    AssignmentSummary,
    createActivityRequest,
    UNASSIGNED_FLAG,
} from '../apis/enquiry';
import { Permission, searchUsersByNameWithPermission, UserSearchResult } from '../apis/users';
import useDebouncedValue from '../hooks/useDebouncedValue';
import { isSuccess, useFetch } from '../hooks/useFetch';
import { getMenuPropsStyle } from '../style/theme';
import { firstCharsInString } from '../util/stringUtils';
import ErrorMessage from './ErrorMessage';

type Props = {
    enquiryIdentifier: string;
    assigneeIdentifier?: string;
    assigneeName?: string;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
    useHoverEffect?: boolean;
};
const DEBOUNCE_MS = 500;

export default function EnquiryAssigneeButton({
    enquiryIdentifier,
    assigneeIdentifier,
    assigneeName,
    addNewActivityCallback,
    useHoverEffect,
}: Readonly<Props>) {
    const theme = useTheme();
    const searchBar = useRef<HTMLInputElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);
    const [hovered, setHovered] = useState<number>(0);
    const [assigneeIdentifierSelected, setAssigneeIdentifierSelected] = useState<string | undefined>('');
    const [assigneeNameSelected, setAssigneeNameSelected] = useState<string | undefined>(UNASSIGNED_FLAG);
    const [assigneeSearchString, setAssigneeSearchString] = useState<string | undefined>('');
    const [assigneeSearchResult, setAssigneeSearchResult] = useState<UserSearchResult[]>([]);

    useEffect(() => {
        setAssigneeIdentifierSelected(assigneeIdentifier);
        setAssigneeNameSelected(assigneeName);
    }, []);

    const debouncedAssigneeSearchString = useDebouncedValue(assigneeSearchString, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchUsersByNameWithPermission({
                page: 0,
                pageSize: 20,
                permission: Permission.ENQUIRY_UPDATE,
                searchString: debouncedAssigneeSearchString,
            }),
        [debouncedAssigneeSearchString, actionMenuOpen],
        {
            canFetch: () => actionMenuOpen,
        }
    );

    useEffect(() => {
        if (isSuccess(state)) {
            setAssigneeSearchResult(state.value.records);
        }
    }, [state]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAssigneeSearchString('');
        setAnchorEl(null);
    };

    const onChangeExpression = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value.toString();
        setAssigneeSearchString(value);
    };

    const onUserSelect = (user: UserSearchResult | null) => {
        const requestBody = {
            activityType: ActivityType.ASSIGNMENT,
            oldAssigneeIdentifier: assigneeIdentifierSelected,
            newAssigneeIdentifier: !user ? null : user.username,
        } as Assignment;
        createActivityRequest(enquiryIdentifier, requestBody).then((newActivity) => {
            const newAssignment = newActivity as AssignmentSummary;
            setAssigneeIdentifierSelected(newAssignment.newAssigneeIdentifier);
            setAssigneeNameSelected(newAssignment.newAssigneeName);
            if (addNewActivityCallback) {
                addNewActivityCallback(newActivity);
            }
            closeActionMenu();
        });
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <Box>
            <Button
                sx={{ width: 160, height: 36.5, whiteSpace: 'nowrap', justifyContent: 'space-between', my: 1, px: 2 }}
                id='enquiry-assignee-action'
                onClick={handleOpenMenu}
                variant='text'
                onMouseEnter={() => setHovered(1)}
                onMouseLeave={() => setHovered(0)}
                endIcon={
                    actionMenuOpen ? <ExpandLess /> : <ExpandMore sx={{ opacity: useHoverEffect ? hovered : 1 }} />
                }
            >
                <Assignee assigneeNameSelected={assigneeNameSelected as string} />
            </Button>

            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onFocus={() => searchBar.current?.focus({ preventScroll: true })}
                slotProps={{ paper: getMenuPropsStyle().PaperProps }}
                MenuListProps={getMenuPropsStyle().MenuListProps}
            >
                <MenuItem
                    value={''}
                    style={{ backgroundColor: grey[100] }}
                    sx={{ p: 2 }}
                    onKeyDown={(e) => {
                        e.stopPropagation();
                    }}
                    onClickCapture={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    <TextField
                        inputRef={searchBar}
                        value={assigneeSearchString}
                        placeholder='Search'
                        onChange={onChangeExpression}
                        fullWidth
                        size='small'
                        style={{ backgroundColor: grey[100] }}
                        InputProps={{
                            style: { backgroundColor: 'white' },
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </MenuItem>

                <MenuItem
                    value={UNASSIGNED_FLAG}
                    sx={{
                        height: 50,
                        px: 3,
                        backgroundColor: assigneeIdentifierSelected === undefined ? blue[50] : 'transparent',
                    }}
                    onClick={() => onUserSelect(null)}
                >
                    <Avatar
                        sx={{ bgcolor: grey[200], width: 30, height: 30, whiteSpace: 'nowrap', mr: 2 }}
                        style={{ fontSize: 12 }}
                    >
                        <Person sx={{ color: grey[500] }} />
                    </Avatar>
                    <ListItemText primary={UNASSIGNED_FLAG} />
                </MenuItem>
                {assigneeSearchResult.map((user: UserSearchResult) => (
                    <MenuItem
                        key={user.uuid}
                        sx={{
                            height: 50,
                            px: 3,
                            backgroundColor: assigneeIdentifierSelected === user.username ? blue[50] : 'transparent',
                        }}
                        onClick={() => onUserSelect(user)}
                    >
                        <Avatar
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                width: 30,
                                height: 30,
                                whiteSpace: 'nowrap',
                                mr: 2,
                            }}
                            style={{ fontSize: 12 }}
                        >
                            {firstCharsInString(user.name)}
                        </Avatar>
                        <ListItemText primary={user.name} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

type AssigneeProps = {
    assigneeNameSelected: string;
};

const Assignee = ({ assigneeNameSelected }: AssigneeProps) => {
    const theme = useTheme();

    if (!assigneeNameSelected || assigneeNameSelected === UNASSIGNED_FLAG) {
        return <Typography color={theme.palette.primary.main}>{UNASSIGNED_FLAG}</Typography>;
    }

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                whiteSpace: 'nowrap',
                alignItems: 'center',
                gap: 1,
            }}
        >
            <Avatar
                sx={{ bgcolor: theme.palette.primary.main, width: 25, height: 25, whiteSpace: 'nowrap' }}
                style={{ fontSize: 12 }}
            >
                {firstCharsInString(assigneeNameSelected)}
            </Avatar>
            <ListItemText
                primary={assigneeNameSelected}
                primaryTypographyProps={{
                    style: {
                        textAlign: 'center',
                        overflow: 'hidden',
                        width: 80,
                        textOverflow: 'ellipsis',
                        ...theme.typography.subtitle2,
                        color: theme.palette.primary.main,
                    },
                }}
            />
        </Box>
    );
};
