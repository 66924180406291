import moment, { Moment } from 'moment';

export const DATE_SERVER_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_SERVER_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const DATE_COMPACT_TIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const DATE_COMPACT = 'DD/MM/YYYY';
export const DATE_FRIENDLY = 'DD MMM YYYY';
export const DATE_FRIENDLY_MDY = 'MMM Do YYYY';
export const TIME_FRIENDLY = 'h:mma';
export const DATE_TIME_FRIENDLY = 'DD MMM YY, h:mma';
export const DATE_VERBOSE = 'dddd, MMM Do, YYYY';
export const DATE_TIME_VERBOSE = 'ddd Do MMM YYYY [at] h:mma';
export const DAY_OF_WEEK = 'dddd';
export const DATE_CARDINAL = 'Do';
export const DATE_DAY_MONTH = 'DD MMM';
export const DATE_FULL = 'D MMMM YYYY';
export const DATE_MONTH_DAY_YEAR_TIME = 'MMM DD, YYYY [at] hh:mm a';

export const UTC_DATE_TIME_FORMAT = 'YYYY-MM-DDTh:mm:ss.000Z';

export const invalidDate = (date: unknown) => {
    return date && date instanceof Date && isNaN(date as unknown as number);
};

export const validDate = (date: unknown): boolean => {
    return date != null && date instanceof Date && !isNaN(date as unknown as number);
};

export const handleDatePickerChange = (field: { onChange: (date: string) => void }, dateFormat?: string) => {
    return (newValue: Moment | null) => {
        field.onChange(asString(newValue, dateFormat));
    };
};

export const asString = (date: Moment | null, dateFormat?: string): string => {
    return date == null ? '' : date.format(dateFormat ?? DATE_SERVER_FORMAT);
};

const inValidRange = (date: Moment, minDate: Moment, maxDate: Moment): boolean => {
    return date.isValid() && date.isSameOrAfter(minDate, 'day') && date.isSameOrBefore(maxDate, 'day');
};

export const getValidateDates =
    (minDate: Moment, maxDate: Moment) =>
    (startDateMoment: Moment | null, endDateMoment: Moment | null): boolean => {
        if (!startDateMoment || !endDateMoment) {
            return false;
        }
        return (
            inValidRange(startDateMoment, minDate, maxDate) &&
            inValidRange(endDateMoment, minDate, maxDate) &&
            startDateMoment.isSameOrBefore(endDateMoment, 'day')
        );
    };

export const getFormattedTime = (dateTime: string | null) => {
    const submittedAt = moment.utc(dateTime).local();
    return submittedAt.format(TIME_FRIENDLY);
};
