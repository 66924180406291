import { ArrowUpward, Print, SwapVert } from '@mui/icons-material';
import { Box, Button, Chip, Dialog, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useMemo, useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { defaultPageSize, Direction, sortOrderToDirection } from '../../apis/common';
import { CancellationRequestSortProperty } from '../../apis/loan';
import ErrorMessage from '../../components/ErrorMessage';
import NoSearchResults from '../../components/NoSearchResults';
import PageLoading from '../../components/PageLoading';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { isError, isSuccess, useFetch } from '../../hooks/useFetch';
import { getWorklistStyles } from '../../style/theme';
import { DATE_FRIENDLY, DATE_SERVER_FORMAT } from '../../util/dateUtils';
import {
    NotificationStatus,
    PrintRequestSortProperty,
    PrintRequestSummary,
    searchPrintRequest,
} from '../../apis/notification';
import { capitalize, lowerCase } from 'lodash';
import PrintDialog from './PrintDialog';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
    notificationStatuses: NotificationStatus[];
    effectiveStartDate: string;
    effectiveEndDate: string;
};
const DEBOUNCE_MS = 500;

export default function List({ notificationStatuses, effectiveStartDate, effectiveEndDate }: Readonly<Props>) {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sortProperty, setSortProperty] = useState(PrintRequestSortProperty.EFFECTIVE_DATE);
    const [direction, setDirection] = useState(Direction.DESC);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([] as PrintRequestSummary[]);
    const [toggleCleared] = useState(false);
    const [printCount, setPrintCount] = useState(0);

    const toggleModal = () => {
        setOpenModal(!openModal);
    };

    const debouncedNotificationRequestStatuses = useDebouncedValue(notificationStatuses, DEBOUNCE_MS);
    const debouncedEffectiveStartDate = useDebouncedValue(effectiveStartDate, DEBOUNCE_MS);
    const debouncedEffectiveEndDate = useDebouncedValue(effectiveEndDate, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchPrintRequest({
                page: page - 1,
                pageSize,
                printRequestSortProperty: sortProperty,
                direction,
                statuses: debouncedNotificationRequestStatuses,
                startDate: debouncedEffectiveStartDate,
                endDate: debouncedEffectiveEndDate,
            }),
        [
            page,
            pageSize,
            sortProperty,
            direction,
            debouncedNotificationRequestStatuses,
            debouncedEffectiveStartDate,
            debouncedEffectiveEndDate,
            printCount,
        ]
    );

    const handleSort = (column: TableColumn<PrintRequestSummary>, sortOrder: SortOrder) => {
        setSortProperty(column.id as PrintRequestSortProperty);
        setDirection(sortOrderToDirection(sortOrder));
    };

    const printingComplete = () => {
        setOpenModal(false);
        setPage(1);
        setPrintCount(printCount + 1);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const contextActions = useMemo(() => {
        const handlePrint = () => {
            setOpenModal(true);
        };

        return (
            <Button variant='contained' startIcon={<Print />} onClick={handlePrint} disabled={selectedRows.length <= 0}>
                Print {selectedRows.length} letters
            </Button>
        );
    }, [selectedRows]);

    const handleRowSelected = (state: { selectedRows: PrintRequestSummary[] }) => {
        setSelectedRows(state.selectedRows);
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <>
            <DataTable
                data={isSuccess(state) ? state.value.records : []}
                columns={columns}
                highlightOnHover={true}
                pagination
                paginationServer
                onSort={handleSort}
                defaultSortAsc={false}
                defaultSortFieldId={CancellationRequestSortProperty.EFFECTIVE_DATE}
                sortIcon={<SortIcons />}
                sortServer
                selectableRows
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                noContextMenu={true}
                actions={contextActions}
                onChangePage={setPage}
                onChangeRowsPerPage={setPageSize}
                progressPending={!isSuccess(state)}
                progressComponent={<PageLoading />}
                noDataComponent={
                    <Box width='100%'>
                        <NoSearchResults />
                    </Box>
                }
                customStyles={getWorklistStyles(sortProperty)}
            />
            <Dialog open={openModal} onClose={toggleModal} disableEscapeKeyDown={true}>
                <PrintDialog printRequests={selectedRows} printingComplete={printingComplete} closeModal={closeModal} />
            </Dialog>
        </>
    );
}

const columns: TableColumn<PrintRequestSummary>[] = [
    {
        name: 'Policy #',
        cell: (row) => renderPolicyNumber(row),
    },
    {
        name: 'Client',
        cell: (row) => renderClientName(row),
    },
    {
        name: 'Notification type',
        cell: (row) => capitalize(lowerCase(row.letterType)),
    },
    {
        name: 'File name',
        selector: (row) => row.fileName,
    },
    {
        name: 'Date',
        selector: (row) => moment(row.effectiveDate, DATE_SERVER_FORMAT).format(DATE_FRIENDLY),
        sortable: true,
        id: PrintRequestSortProperty.EFFECTIVE_DATE,
    },
    {
        name: 'Print status',
        cell: (row) => renderPrintStatus(row.status),
        sortable: true,
        id: PrintRequestSortProperty.STATUS,
    },
];

function renderPolicyNumber(row: PrintRequestSummary) {
    return (
        <Link component={RouterLink} to={'/invoices/' + row.invoiceUuid}>
            {row.policyNumber}
        </Link>
    );
}

function renderClientName(row: PrintRequestSummary) {
    return (
        <Link component={RouterLink} to={'/clients/' + row.clientUuid}>
            {row.clientName}
        </Link>
    );
}

function renderPrintStatus(status: NotificationStatus) {
    switch (status) {
        case NotificationStatus.SCHEDULED:
        case NotificationStatus.SUBMITTED:
            return <Chip label='Ready to print' color='primary' size='small' variant='outlined' />;
        case NotificationStatus.SENT:
            return <Chip label='Printed' color='success' size='small' variant='outlined' />;
    }
}

const SortIcons = () => (
    <Box sx={{ display: 'flex' }}>
        <Box sx={{ color: grey[600] }}>
            <ArrowUpward className='selectedSort' />
        </Box>
        <Box sx={{ color: grey[400] }}>
            <SwapVert className='unselectedSort' />
        </Box>
    </Box>
);
