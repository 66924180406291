import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, ButtonProps, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import {
    ActivityType,
    ActivityVariation,
    createActivityRequest,
    EnquiryStatus,
    StatusUpdate,
} from '../../../apis/enquiry';
import { getFriendlyEnumsString } from '../../../util/stringUtils';

type Props = {
    enquiryIdentifier: string;
    enquiryStatus?: EnquiryStatus;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
    enquiryStatusUpdateCallback?: (newEnquiryStatus: EnquiryStatus) => void;
};

export default function EnquiryStatusAction({
    enquiryIdentifier,
    enquiryStatus,
    addNewActivityCallback,
    enquiryStatusUpdateCallback,
}: Readonly<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    const createActivity = (data: EnquiryStatus) => {
        const requestBody = {
            activityType: ActivityType.STATUS_UPDATE,
            oldStatus: enquiryStatus,
            newStatus: data,
        } as StatusUpdate;

        createActivityRequest(enquiryIdentifier, requestBody).then((newActivity) => {
            if (addNewActivityCallback) {
                addNewActivityCallback(newActivity);
            }
            if (enquiryStatusUpdateCallback) {
                enquiryStatusUpdateCallback(data);
            }
            closeActionMenu();
        });
    };
    return (
        <Box>
            <Button
                sx={{
                    width: 160,
                    boxShadow: 'none',
                    justifyContent: 'space-between',
                    my: 1,
                    ':hover': { boxShadow: 'none' },
                }}
                id='enquiry-status-action'
                onClick={handleOpenMenu}
                variant='contained'
                color={getVariantByStatus(enquiryStatus)}
                endIcon={actionMenuOpen ? <ExpandLess /> : <ExpandMore />}
            >
                {getFriendlyEnumsString(enquiryStatus as EnquiryStatus)}
            </Button>

            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                MenuListProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
                slotProps={{ paper: { sx: { minWidth: 280 } } }}
            >
                {Object.values(EnquiryStatus).map((enquiryStatus) => {
                    return (
                        <MenuItem key={enquiryStatus} onClick={() => createActivity(enquiryStatus)}>
                            <ListItemText>{getFriendlyEnumsString(enquiryStatus)}</ListItemText>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
}

const getVariantByStatus = (enquiryStatus?: EnquiryStatus): ButtonProps['color'] => {
    switch (enquiryStatus) {
        case EnquiryStatus.IN_PROGRESS:
            return 'info';
        case EnquiryStatus.NEW:
            return 'inherit';
        case EnquiryStatus.ON_HOLD:
            return 'warning';
        case EnquiryStatus.SPAM:
            return 'inherit';
        case EnquiryStatus.CLOSED:
            return 'success';
        default:
            return 'inherit';
    }
};
