import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { LoanArrangementType } from '../../apis/loan';
import List from './List';
import { getMenuPropsStyle } from '../../style/theme';

export default function OverdueLoans() {
    const [inArrangements, setInArrangements] = useState<LoanArrangementType[]>([
        LoanArrangementType.SETUP_ARRANGEMENT,
        LoanArrangementType.NONE,
    ]);
    const [dpdStart, setDpdStart] = useState<string | undefined>('0');
    const [dpdEnd, setDpdEnd] = useState<string | undefined>('150');

    const handleChange = (event: SelectChangeEvent<typeof inArrangements>) => {
        const {
            target: { value },
        } = event;
        setInArrangements((typeof value === 'string' ? value.split(',') : value) as LoanArrangementType[]);
    };

    return (
        <Box>
            <Typography variant='h3' component='h1' mb={1}>
                Arrears management
            </Typography>
            <Typography mb={4}>View and manage overdue payments.</Typography>
            <Paper variant={'flat'} sx={{ py: 3, px: 2 }}>
                <Box sx={{ display: 'flex', gap: 3, pb: 4 }}>
                    <FormControl size='small' sx={{ minWidth: 280 }}>
                        <FormLabel htmlFor='prmosie-to-pay-multiple-checkbox' sx={{ mb: 1 }}>
                            Promise to pay
                        </FormLabel>
                        <Select
                            multiple
                            value={inArrangements}
                            onChange={handleChange}
                            input={<OutlinedInput id='prmosie-to-pay-multiple-checkbox' />}
                            renderValue={(selected: LoanArrangementType[]) => {
                                if (
                                    selected.length === Object.values(LoanArrangementType).length ||
                                    selected.length === 0
                                ) {
                                    return 'Any';
                                }

                                return selected.map(getFriendlyArrangementType).join(', ');
                            }}
                            MenuProps={getMenuPropsStyle().PaperProps}
                        >
                            {Object.values(LoanArrangementType).map((type) => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={inArrangements.indexOf(type) > -1} />
                                    <ListItemText primary={getFriendlyArrangementType(type)} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box>
                        <FormLabel sx={{ mb: 1 }}>Days past due</FormLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <OutlinedInput
                                size='small'
                                type='number'
                                sx={{ width: 72 }}
                                value={dpdStart}
                                inputProps={{ min: 0 }}
                                onChange={(e) => setDpdStart(e.target.value)}
                            />
                            <span>-</span>
                            <OutlinedInput
                                size='small'
                                type='number'
                                sx={{ width: 72 }}
                                value={dpdEnd}
                                onChange={(e) => setDpdEnd(e.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <List inArrangements={inArrangements} dpdStartString={dpdStart} dpdEndString={dpdEnd} />
            </Paper>
        </Box>
    );
}

const getFriendlyArrangementType = (type: LoanArrangementType) => {
    switch (type) {
        case LoanArrangementType.SETUP_ARRANGEMENT:
            return 'Active';
        default:
            return 'None';
    }
};
