export interface Note {
    uuid: string;
    user: string;
    contents: string;
    createdDate: string;
}

export type Address = {
    uuid: string;
    addressType: AddressType;
    suburb: string;
    city: string;
    postCode: string;
    addressLine1: string;
    addressLine2: string;
    preferred: boolean;
    fromDate?: string;
    toDate?: string;
};

export type ContactDetails = {
    emails: Email[];
    addresses: Address[];
    phoneNumbers: PhoneNumber[];
    email: Email;
    preferredAddress: Address;
    preferredPhoneNumber: PhoneNumber;
};

export type PhoneNumber = {
    uuid: string;
    number: string;
    dialingCode: string;
    preferred: boolean;
    fromDate?: string;
    toDate?: string;
};

export type Email = {
    uuid: string;
    address: string;
    emailType: EmailType;
    preferred: boolean;
    fromDate?: string;
    toDate?: string;
};

export type PersonalDetails = {
    title: string;
    gender: string;
    surname: string;
    givenName: string;
    middleName: string;
    dateOfBirth?: Date;
};

export enum AddressType {
    PHYSICAL = 'PHYSICAL',
    POSTAL = 'POSTAL',
}

export enum EmailType {
    WORK = 'WORK',
    PERSONAL = 'PERSONAL',
}
