import { ExpandLess, ExpandMore, Launch } from '@mui/icons-material';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { EnquiryConfiguration } from '../../apis/enquiry';

type Props = {
    data: EnquiryConfiguration[];
};

export default function NewEnquiryAction({ data }: Readonly<Props>) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeActionMenu = () => {
        setAnchorEl(null);
    };

    if (data.length == 0) {
        return null;
    }

    return (
        <Box sx={{ alignSelf: 'center' }}>
            <Button
                sx={{ ...buttonStyle, justifyContent: 'space-between' }}
                id='enquiry-action'
                onClick={handleOpenMenu}
                variant='contained'
                endIcon={actionMenuOpen ? <ExpandLess /> : <ExpandMore />}
            >
                New enquiry
            </Button>

            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                slotProps={{ paper: { sx: { minWidth: 280 } } }}
            >
                {data.map((enquiryConfiguration) => {
                    return (
                        <MenuItem
                            key={enquiryConfiguration.uuid}
                            onClick={() => window.open(enquiryConfiguration.standaloneFormUrl, '_blank')?.focus()}
                        >
                            <ListItemText>{enquiryConfiguration.enquiryConfigurationDetail.formName}</ListItemText>
                            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                                <Launch fontSize='small' />
                            </ListItemIcon>
                        </MenuItem>
                    );
                })}
            </Menu>
        </Box>
    );
}
const buttonStyle = { width: 160 };
