import { Box, Grid, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { Invoice, Policy } from '../../../apis/invoice';
import PolicyDetailFields from '../../../components/PolicyDetailFields';
import { BaseInvoiceFormFields, tallyPremiums } from '../../CreateInvoice/invoiceValidation';

interface Props {
    control: Control<BaseInvoiceFormFields>;
    fields: FieldArrayWithId[];
    append: UseFieldArrayAppend<BaseInvoiceFormFields, 'policies'>;
    remove: UseFieldArrayRemove;
    errors: FieldErrors<BaseInvoiceFormFields>;
    register: UseFormRegister<BaseInvoiceFormFields>;
    setValue: UseFormSetValue<BaseInvoiceFormFields>;
    invoice: Invoice;
}

export default function PortfolioDetails({
    setValue,
    fields,
    append,
    control,
    errors,
    register,
    invoice,
}: Readonly<Props>) {
    const [totalPremiums, setTotalPremiums] = useState(invoice.totalPremiums);
    const policies = useWatch({ control, name: 'policies' });

    useEffect(() => {
        const premiums = tallyPremiums(policies);
        setTotalPremiums(premiums);
    }, [policies]);

    useEffect(() => {
        if (fields.length == 0) {
            addPolicy();
        }
    }, []);

    const addPolicy = () => {
        append({
            number: '',
            provider: '',
            currentPolicyVersion: {
                annualPremiums: '' as unknown as number,
                effectiveDate: '',
                anniversaryDate: '',
                endDate: '',
            },
            classCode: '',
        } as Policy);
    };

    return (
        <Box sx={{ pt: 2 }}>
            <Typography variant='subtitle2'>Enter policy details</Typography>
            <Box sx={{ p: 1, display: 'flex', bgcolor: blueGrey[50], border: `1px solid ${grey[400]}`, mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant='caption'>Number</Typography>
                    </Grid>
                    {['Provider', 'Classcode', 'Start date', 'Premium'].map((header) => (
                        <Grid key={header} item xs={2}>
                            <Typography variant='caption'>{header}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PolicyDetailFields
                    control={control}
                    fields={fields}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1, mt: 2 }}>
                <Typography variant='caption'>Total premiums {currencyFormat.format(totalPremiums)}</Typography>
            </Box>
        </Box>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
