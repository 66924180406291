import axios from 'axios';
import { assignDefaultListParams, Direction, ListParams, SearchResult } from './common';

export enum EnquiryCreateRequestKeys {
    CLIENT_NAME = 'clientName',
    EMAIL_ADDRESS = 'emailAddress',
    MOBILE_NUMBER = 'mobileNumber',
}

export type EnquiryDetailedSummary = {
    uuid: string;
    status: EnquiryStatus;
    contactDateTime: string;
    latestAssigneeIdentifier: string;
    latestAssigneeName: string;
    enquiryConfigurationDetailUuid: string;
    enquiryIdentifiers: EnquiryIdentifier[];
    enquiryDetail: EnquiryDetail;
    activities: Activity[];
};

export type EnquiryIdentifier = {
    identifierType: string;
    identifier: string;
};

export type EnquiryType = {
    uuid: string;
    name: string;
};

export type EnquiryDetail = {
    clientName: string;
    emailAddress: string;
    mobileNumber: string;
    enquiryType: string;
    rawDetails: string;
};

export enum ActivityType {
    ASSIGNMENT = 'ASSIGNMENT',
    CONTACT = 'CONTACT',
    NOTE = 'NOTE',
    STATUS_UPDATE = 'STATUS_UPDATE',
    INITIAL = 'INITIAL',
}

export type Activity = {
    uuid: string;
    activityType: ActivityType;
    createdDate: string;
    sellerUserIdentifier: string;
    sellerUserName: string;
};

export enum ContactType {
    CALL_OUTBOUND = 'CALL_OUTBOUND',
    CALL_INBOUND = 'CALL_INBOUND',
    SMS = 'SMS',
    EMAIL = 'EMAIL',
}

export enum OutcomeType {
    COMPLETED_CALL = 'COMPLETED_CALL',
    LEFT_VOICEMAIL = 'LEFT_VOICEMAIL',
    CALL_FAILED = 'CALL_FAILED',
    CALL_ATTEMPTED_NO_ANSWER = 'CALL_ATTEMPTED_NO_ANSWER',
    CALL_DISCONNECTED = 'CALL_DISCONNECTED',
    BUSY_SIGNAL = 'BUSY_SIGNAL',
    OTHER = 'OTHER',
}

export type Contact = Activity & {
    contactType: ContactType;
    contactDateTime: string;
    outcomeType: OutcomeType | null;
    noteDetails: string;
};

export type Assignment = Activity & {
    oldAssigneeIdentifier: string;
    newAssigneeIdentifier: string;
};

export type AssignmentSummary = Assignment & {
    oldAssigneeName: string;
    newAssigneeName: string;
};

export type Note = Activity & {
    details: string;
};

export enum EnquiryStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    ON_HOLD = 'ON_HOLD',
    SPAM = 'SPAM',
    CLOSED = 'CLOSED',
}

export type StatusUpdate = Activity & {
    oldStatus: EnquiryStatus;
    newStatus: EnquiryStatus;
};

export type Initial = {
    uuid: string;
    activityType: ActivityType.INITIAL;
    createdDate: string;
};

export type ActivityVariation = Activity | Initial | Contact | AssignmentSummary | Assignment | Note | StatusUpdate;

export type ActivityPage = SearchResult<ActivityVariation>;

export type EnquirySummary = {
    uuid: string;
    enquiryType: string;
    clientName: string;
    createdDate: string;
    lastContactDateTime?: string;
    status: EnquiryStatus;
    assigneeIdentifier?: string;
    assigneeName?: string;
};

export type EnquiriesPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: EnquirySummary[];
};

export enum EnquirySortProperty {
    TYPE = 'TYPE',
    NAME = 'NAME',
    ENQUIRY_DATE = 'ENQUIRY_DATE',
    LAST_CONTACT_DATE_TIME = 'LAST_CONTACT_DATE_TIME',
    STATUS = 'STATUS',
    ASSIGNEE = 'ASSIGNEE',
}

type ListEnquiriesParams = ListParams & {
    enquirySortProperty: EnquirySortProperty;
    direction: Direction;
    enquiryCreateStartString?: string;
    enquiryCreateEndString?: string;
    enquiryTypes?: string[];
    enquiryStatuses?: EnquiryStatus[];
    enquiryDateFrom?: string;
    enquiryDateTo?: string;
    assigneeIdentifiers?: string[];
    includeNullAssignee: boolean;
};

export type EnquiryFormOrigin = {
    url: string;
};

export type EnquiryConfigurationDetail = {
    uuid: string;
    formName: string;
    active: boolean;
    description: string;
    formConfiguration: string;
    version: string;
    completionMessage: string;
    enquiryType: EnquiryType;
    enquiryFormOrigins: EnquiryFormOrigin[];
};

export type EnquiryConfiguration = {
    uuid: string;
    standaloneFormUrl: string;
    enquiryConfigurationDetail: EnquiryConfigurationDetail;
};

export type BaseFormDetail = {
    name: string;
    label: string;
};

export type NumberFormDetail = BaseFormDetail & {
    type: 'number';
    value?: string;
};

export type MultiSelectFormDetail = BaseFormDetail & {
    type: 'radio-group' | 'checkbox-group' | 'select';
    values: {
        label: string;
        value: string;
        selected: boolean;
    }[];
};

export type DateFormDetail = BaseFormDetail & {
    type: 'date';
    value?: string;
};

export type TextFormDetail = BaseFormDetail & {
    type: 'text';
    subtype: 'text' | 'email' | 'tel';
    value?: string;
};

export type TextAreaFormDetail = BaseFormDetail & {
    type: 'textarea';
    value?: string;
};

export type HiddenFormDetail = BaseFormDetail & {
    type: 'hidden';
    value?: string;
};

export type EnquiryFormFields =
    | NumberFormDetail
    | MultiSelectFormDetail
    | DateFormDetail
    | TextFormDetail
    | TextAreaFormDetail
    | HiddenFormDetail;

export const UNASSIGNED_FLAG = 'Unassigned';

export const fetchActiveEnquiryConfigurations = async (): Promise<EnquiryConfiguration[]> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/configuration/_query_all_active`)
        .then(({ data }) => data);
};

export const fetchEnquiryTypes = async (): Promise<EnquiryType[]> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/configuration/types`)
        .then(({ data }) => {
            if (data.length == 0) {
                throw new Error('No enquiry types found');
            }
            return data;
        });
};

export const searchEnquiries = async (partial?: Partial<ListEnquiriesParams>): Promise<EnquiriesPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/_query/worklist`, searchRequest)
        .then(({ data }) => data);
};

export const fetchEnquiryDetails = async (enquiryIdentifier: string): Promise<EnquiryDetailedSummary> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/${enquiryIdentifier}`)
        .then(({ data }) => data);
};

export const fetchEnquiryActivities = async (
    enquiryIdentifier: string,
    partial?: Partial<ListParams>
): Promise<ActivityPage> => {
    const searchRequest = assignDefaultListParams(partial);

    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/${enquiryIdentifier}/_query/activities`,
            searchRequest
        )
        .then(({ data }) => data);
};

export const createActivityRequest = async <T extends ActivityVariation>(
    enquiryIdentifier: string,
    requestBody: T
): Promise<T> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/${enquiryIdentifier}/activity`, requestBody)
        .then(({ data }) => data);
};
