import { Box, Chip } from '@mui/material';
import { upperFirst } from 'lodash';
import { ExpectedPayment, ExpectedPaymentCausedByType, ExpectedPaymentStatus } from '../apis/invoice';
import useDefaultFeeLabel from '../hooks/useDefaultFeeLabel';
import ExpectedPaymentStatusChip from './ExpectedPaymentStatusChip';

type Props = {
    expectedPaymentStatus: ExpectedPaymentStatus;
    expectedPayments: ExpectedPayment[];
};

export default function DetailedExpectedPaymentStatusChips({
    expectedPaymentStatus,
    expectedPayments,
}: Readonly<Props>) {
    const defaultFeeLabel = useDefaultFeeLabel();

    const includesDefaultFees = expectedPayments.some((ep) => ep.causedBy === ExpectedPaymentCausedByType.DEFAULT_FEE);

    return (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <ExpectedPaymentStatusChip expectedPaymentStatus={expectedPaymentStatus} />
            {includesDefaultFees && <Chip label={upperFirst(defaultFeeLabel)} color='warning' variant='outlined' />}
        </Box>
    );
}
