import {
    Alert,
    Box,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { Address, AddressType, ContactDetails } from '../../../types/Types';
import AddressFields from '../../../components/AddressFields';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { LoadingButton } from '../../../components/LoadingButton';
import { Client, CreateAddressFields, createAddressPromise, updateAddressPromise } from '../../../apis/clients';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange } from '../../../util/dateUtils';
import moment from 'moment/moment';

type Props = {
    address?: Address;
    client: Client;
    callbackAddressUpdate: (contactDetails: ContactDetails) => void;
};

const schema = yup.object({
    addressLine1: yup.string().required('Address line 1 required'),
    addressLine2: yup.string().optional(),
    suburb: yup.string().optional(),
    cityTown: yup.string().optional(),
    postcode: yup.string().optional(),
    addressType: yup.string().required('Address type is required'),
    fromDate: yup.date().required('Start date is required'),
});

export default function AddressForm({ address, client, callbackAddressUpdate }: Readonly<Props>) {
    const [submitting, setSubmitting] = useState(false);
    const [errorMsg, setErrorMsg] = useState<string>();
    const addressUuid = address?.uuid;

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm<CreateAddressFields>({
        resolver: yupResolver(schema),
        defaultValues: useMemo(() => {
            return {
                suburb: address?.suburb,
                cityTown: address?.city,
                postcode: address?.postCode,
                addressLine1: address?.addressLine1,
                addressLine2: address?.addressLine2,
                addressType: address?.addressType,
                preferred: address?.preferred,
                fromDate: address?.fromDate,
                toDate: address?.toDate,
            } as CreateAddressFields;
        }, [address]),
    });

    const onSubmit: SubmitHandler<CreateAddressFields> = (data) => {
        setErrorMsg(undefined);
        setSubmitting(true);
        if (addressUuid) {
            updateAddress(client.uuid, addressUuid, data);
        } else {
            createAddress(client.uuid, data);
        }
    };

    const updateAddress = async (clientUuid: string, addressUuid: string, addressFields: CreateAddressFields) => {
        updateAddressPromise(clientUuid, addressUuid, addressFields)
            .then((contactDetails: ContactDetails) => {
                callbackAddressUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    const createAddress = async (clientUuid: string, addressFields: CreateAddressFields) => {
        createAddressPromise(clientUuid, addressFields)
            .then((contactDetails: ContactDetails) => {
                callbackAddressUpdate(contactDetails);
            })
            .catch((errorMessage) => setErrorMsg(errorMessage))
            .finally(() => setSubmitting(false));
    };

    return (
        <form
            autoComplete='off'
            onSubmit={handleSubmit(onSubmit)}
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'space-between',
            }}
        >
            <DialogTitle>{address ? <>Add new address</> : <>Update address</>}</DialogTitle>

            <DialogContent sx={{ width: 480 }}>
                <Box sx={{ mb: 2 }}>
                    <FormControl component='fieldset'>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name='addressType'
                            render={({ field }) => (
                                <RadioGroup {...field} row name='addressType' id='addressType'>
                                    <FormControlLabel
                                        value={AddressType.PHYSICAL}
                                        control={<Radio />}
                                        label='Physical'
                                    />
                                    <FormControlLabel value={AddressType.POSTAL} control={<Radio />} label='Postal' />
                                </RadioGroup>
                            )}
                        />
                        {!!errors?.addressType && (
                            <FormHelperText error={!!errors?.addressType}>
                                {errors?.addressType?.message}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>

                <Box sx={{ mb: 2 }}>
                    <AddressFields
                        errors={errors}
                        control={control}
                        setValue={setValue}
                        address={address}
                        clearErrors={clearErrors}
                    />
                </Box>

                <Box sx={{ mb: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <FormControl required>
                                <FormLabel htmlFor='fromDate'>Start from</FormLabel>
                                <Controller
                                    name='fromDate'
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: 'fromDate',
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    helperText: errors?.fromDate?.message,
                                                    error: !!errors?.fromDate,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel htmlFor='toDate'>End date</FormLabel>
                                <Controller
                                    name='toDate'
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            onChange={handleDatePickerChange(field)}
                                            onAccept={handleDatePickerChange(field)}
                                            value={moment(field.value, DATE_SERVER_FORMAT)}
                                            inputRef={field.ref}
                                            slotProps={{
                                                textField: {
                                                    id: 'endDate',
                                                    fullWidth: true,
                                                    variant: 'outlined',
                                                    helperText: errors?.toDate?.message,
                                                    error: !!errors?.toDate,
                                                },
                                            }}
                                            format={DATE_COMPACT}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Box>
                    </LocalizationProvider>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Box sx={{ position: 'relative' }}>
                        <FormControlLabel
                            control={
                                <Controller
                                    name='preferred'
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            {...field}
                                            id='confirm-authority'
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                    )}
                                />
                            }
                            label='Set as preferred contact address'
                        />
                    </Box>
                </Box>

                {errorMsg && <Alert severity='error'>{errorMsg}</Alert>}
            </DialogContent>
            <DialogActions>
                <Button variant='text' color='primary'>
                    Close
                </Button>
                <LoadingButton data-testid='saveClient' type='submit' variant='contained' loading={submitting}>
                    Save
                </LoadingButton>
            </DialogActions>
        </form>
    );
}
