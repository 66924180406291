import { Box, FormLabel, Paper, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { Invoice } from '../../../apis/invoice';
import { updateCpiEnabled } from '../../../apis/policy';
import SnackAlert, { SnackState } from '../../../components/SnackAlert';

type Props = {
    invoice: Invoice;
};

export default function PolicyOptionsCard({ invoice }: Readonly<Props>) {
    const [cpiIncreaseEnabled, setCpiIncreaseEnabled] = useState(invoice.portfolio.policies[0].cpiIncreaseEnabled);
    const [cpiUpdateToastState, setCpiUpdateToastState] = useState<SnackState>();

    const handleUpdateCPIIncreaseEnabled = () => {
        const enabled = !cpiIncreaseEnabled;
        setCpiIncreaseEnabled(enabled);
        updateCpiEnabled(invoice.uuid, invoice.portfolio.policies[0].uuid, enabled)
            .then(() => {
                setCpiUpdateToastState({
                    severity: 'success',
                    msg: `CPI increase set to ${enabled ? 'enabled' : 'disabled'}`,
                    anchorOriginVertical: 'bottom',
                    anchoreOriginHorizontal: 'right',
                });
            })
            .catch(() => {
                setCpiUpdateToastState({
                    severity: 'error',
                    msg: 'Something went wrong, please try again',
                    anchorOriginVertical: 'bottom',
                    anchoreOriginHorizontal: 'right',
                });
                setCpiIncreaseEnabled(!enabled);
            });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='h6' component='h2'>
                Policy Options
            </Typography>
            <Paper variant='flat' sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormLabel htmlFor='cpi-toggle' sx={{ pb: 0 }}>
                        <Typography variant='caption'>
                            {cpiIncreaseEnabled ? 'CPI Increase (enabled)' : 'CPI Increase (disabled)'}
                        </Typography>
                    </FormLabel>
                    <Switch
                        id='cpi-toggle'
                        checked={cpiIncreaseEnabled}
                        onClick={handleUpdateCPIIncreaseEnabled}
                        size='medium'
                    />
                    <SnackAlert
                        state={cpiUpdateToastState}
                        open={!!cpiUpdateToastState}
                        closeSnackbar={() => setCpiUpdateToastState(undefined)}
                    />
                </Box>
            </Paper>
        </Box>
    );
}
