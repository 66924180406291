import { QuestionMark } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import {
    ActivityType,
    ActivityVariation,
    AssignmentSummary,
    Contact,
    ContactType,
    Note,
    StatusUpdate,
    UNASSIGNED_FLAG,
} from '../../../apis/enquiry';
import { DATE_FRIENDLY_MDY } from '../../../util/dateUtils';
import { getFriendlyEnumsString, getFullName } from '../../../util/stringUtils';
import { getFormattedDate, prepareContactTitle } from './utils';

type Props = {
    bodyItem: ActivityVariation;
};

export default function ActivityItemComponent({ bodyItem }: Readonly<Props>) {
    const formattedDate = getFormattedDate(bodyItem);

    switch (bodyItem.activityType) {
        case ActivityType.CONTACT: {
            const contact = bodyItem as Contact;
            const contactType = contact.contactType;
            const title = prepareContactTitle(contactType);
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
                    <Typography variant='subtitle2'>{title}</Typography>
                    <Typography variant='caption'>{formattedDate}</Typography>

                    <Paper variant='flat' sx={{ width: '100%', backgroundColor: grey[50], px: 2, py: 3, mt: 2 }}>
                        {contactType === ContactType.CALL_OUTBOUND && contact.outcomeType && (
                            <Typography variant='subtitle1'>
                                Contact Outcome: {getFriendlyEnumsString(contact.outcomeType)}
                            </Typography>
                        )}
                        <Typography variant='subtitle2'>{contact.noteDetails}</Typography>
                    </Paper>
                </Box>
            );
        }
        case ActivityType.ASSIGNMENT: {
            const assignment = bodyItem as AssignmentSummary;
            const from = !assignment.oldAssigneeIdentifier ? UNASSIGNED_FLAG : getFullName(assignment.oldAssigneeName);
            const to = !assignment.newAssigneeIdentifier ? UNASSIGNED_FLAG : getFullName(assignment.newAssigneeName);
            return (
                <Box sx={{ mb: 3 }}>
                    <Typography variant='subtitle2'>{`Assignee was updated from ${from} to ${to}`}</Typography>
                    <Typography variant='caption'>{formattedDate}</Typography>
                </Box>
            );
        }
        case ActivityType.STATUS_UPDATE: {
            const statusUpdate = bodyItem as StatusUpdate;
            return (
                <Box sx={{ mb: 3 }}>
                    <Typography variant='subtitle2'>{`Status updated from ${getFriendlyEnumsString(statusUpdate.oldStatus)} to ${getFriendlyEnumsString(statusUpdate.newStatus)}`}</Typography>
                    <Typography variant='caption'>{formattedDate}</Typography>
                </Box>
            );
        }
        case ActivityType.NOTE: {
            const note = bodyItem as Note;
            return (
                <Box sx={{ mb: 3, width: '100%' }}>
                    <Typography variant='subtitle2'>Internal note added</Typography>
                    <Typography variant='caption'>{formattedDate}</Typography>

                    <Paper variant='flat' sx={{ width: '100%', backgroundColor: grey[50], px: 2, py: 3, mt: 2 }}>
                        <Typography variant='subtitle2'>{note.details}</Typography>
                    </Paper>
                </Box>
            );
        }
        case ActivityType.INITIAL: {
            return (
                <Box sx={{ mb: 3 }}>
                    <Typography variant='subtitle2'>{`Enquiry received via Client Enquiry form`}</Typography>
                    <Typography variant='caption'>
                        {moment.utc(bodyItem.createdDate).local().format(DATE_FRIENDLY_MDY)}
                    </Typography>
                </Box>
            );
        }
        default:
            return <QuestionMark />;
    }
}
