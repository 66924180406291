import axios from 'axios';
import { Policy } from './invoice';

export const updateCpiEnabled = async (
    invoiceIdentifier: string,
    policyIdentifier: string,
    enabled: boolean
): Promise<Policy> => {
    return await axios
        .patch(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceIdentifier}/policies/${policyIdentifier}/_action/update-cpi_increase-enabled?enabled=${enabled}`
        )
        .then(({ data }) => data);
};
