import { Box, Divider, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { TimelineObject } from '.';
import { getFormattedTime } from '../../util/dateUtils';

type Props<T> = {
    lineItem: TimelineObject<T>;
    isFirst: boolean;
    hasTail: boolean;
    DayContentComponent: (props: { bodyItem: T }) => JSX.Element;
};

const TIME_WIDTH = '80px';
const ICON_WIDTH = '42px';
const DIVIDER_HEIGHT = '44px';

export default function LineItem<T>({ lineItem, isFirst, hasTail, DayContentComponent }: Readonly<Props<T>>) {
    const { IconComponent } = lineItem;

    return (
        <Box>
            {isFirst && <DividerRow />}

            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, maxWidth: TIME_WIDTH }}>
                    <Box sx={{ width: TIME_WIDTH, mt: 1 }}>
                        <Typography>{getFormattedTime(lineItem.sortableDateTime)}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flexGrow: 1,
                        maxWidth: ICON_WIDTH,
                    }}
                >
                    <Box
                        sx={{
                            width: ICON_WIDTH,
                            height: ICON_WIDTH,
                            borderRadius: ICON_WIDTH,
                            backgroundColor: grey[200],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: lineItem.iconColour,
                        }}
                    >
                        <IconComponent />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                        <Divider orientation='vertical' />
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
                    <Box ml={1}>
                        <DayContentComponent bodyItem={lineItem.displayObj} />
                    </Box>
                </Box>
            </Box>

            {hasTail && <DividerRow />}
        </Box>
    );
}

const DividerRow = () => {
    return (
        <Box sx={{ height: DIVIDER_HEIGHT, display: 'flex' }}>
            <Box width={TIME_WIDTH}></Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: ICON_WIDTH }}>
                <Divider orientation='vertical' sx={{ minHeight: DIVIDER_HEIGHT }} />
            </Box>
        </Box>
    );
};
