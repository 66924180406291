import { Box, Typography } from '@mui/material';

export default ({ value, caption }: { value: string; caption: string }) => {
    return (
        <Box sx={{ pb: 1 }}>
            <Typography variant='caption'>{caption}</Typography>
            <Typography variant='body2'>{value}</Typography>
        </Box>
    );
};
