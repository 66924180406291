import {
    Box,
    Checkbox,
    FormControl,
    FormLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DATE_COMPACT, DATE_SERVER_FORMAT } from '../../util/dateUtils';
import moment, { Moment } from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { CalendarToday } from '@mui/icons-material';
import { NotificationStatus } from '../../apis/notification';
import { upperFirst } from 'lodash';
import List from './List';
import { renderValues } from '../../util/enumUtils';

export default function Print() {
    const [notificationStatuses, setNotificationStatuses] = useState<NotificationStatus[]>([
        NotificationStatus.SCHEDULED,
        NotificationStatus.SENT,
    ]);

    const [effectiveStartDate, setEffectiveStartDate] = useState<string>('');
    const [effectiveEndDate, setEffectiveEndDate] = useState<string>('');

    const handleEffectiveStartDateChange = (startDateInput: Moment | null) => {
        setEffectiveStartDate(startDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleEffectiveEndDateChange = (endDateInput: Moment | null) => {
        setEffectiveEndDate(endDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleNotificationStatusesChange = (event: SelectChangeEvent<typeof notificationStatuses>) => {
        const {
            target: { value },
        } = event;
        setNotificationStatuses((typeof value === 'string' ? value.split(',') : value) as NotificationStatus[]);
    };

    return (
        <Box>
            <Typography variant='h3' component='h1' mb={1}>
                Batch print
            </Typography>
            <Typography mb={4}>Select letters to print</Typography>
            <Paper variant={'flat'} sx={{ py: 3, px: 2 }}>
                <Box sx={{ display: 'flex', gap: 3, pb: 4 }}>
                    <FormControl size='small' sx={{ minWidth: 280 }}>
                        <FormLabel htmlFor='print-status-select' sx={{ mb: 1 }}>
                            Print Status
                        </FormLabel>
                        <Select
                            multiple
                            value={notificationStatuses}
                            input={<OutlinedInput id='print-status-select' />}
                            onChange={handleNotificationStatusesChange}
                            renderValue={(selected: NotificationStatus[]) =>
                                renderValues(selected, Object.values(NotificationStatus), getFriendlyNotificationStatus)
                            }
                        >
                            {Object.values(NotificationStatus).map((type) => (
                                <MenuItem key={type} value={type}>
                                    <Checkbox checked={notificationStatuses.indexOf(type) > -1} />
                                    <ListItemText primary={type} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <FormControl>
                            <FormLabel htmlFor='effective-date' sx={{ mb: 1 }}>
                                Letter date
                            </FormLabel>
                            <Box sx={{ display: 'flex' }}>
                                <DatePicker
                                    label='Start date'
                                    onChange={handleEffectiveStartDateChange}
                                    sx={{ mr: 1, width: '175px' }}
                                    slotProps={{
                                        textField: {
                                            id: 'startDate',
                                            size: 'small',
                                        },
                                    }}
                                    slots={{
                                        openPickerIcon: CalendarToday,
                                    }}
                                    format={DATE_COMPACT}
                                    maxDate={moment(effectiveEndDate)}
                                />

                                <DatePicker
                                    label='End date'
                                    onChange={handleEffectiveEndDateChange}
                                    sx={{ mr: 1, width: '175px' }}
                                    slotProps={{
                                        textField: {
                                            id: 'endDate',
                                            size: 'small',
                                        },
                                    }}
                                    slots={{
                                        openPickerIcon: CalendarToday,
                                    }}
                                    format={DATE_COMPACT}
                                    minDate={moment(effectiveStartDate)}
                                />
                            </Box>
                        </FormControl>
                    </LocalizationProvider>
                </Box>
                <List
                    notificationStatuses={notificationStatuses}
                    effectiveStartDate={effectiveStartDate}
                    effectiveEndDate={effectiveEndDate}
                />
            </Paper>
        </Box>
    );
}

const getFriendlyNotificationStatus = (notificationStatus: NotificationStatus): string => {
    return upperFirst(notificationStatus.toLowerCase().replace('_', ' '));
};
