import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { useState } from 'react';
import { Communication } from '../../apis/communications';
import Details from './Details';
import { readableNotificationType } from './utils';

type Props = {
    bodyItem: Communication;
};

export default function CommunicationBody({ bodyItem }: Readonly<Props>) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <Box>
            <Box ml={1}>
                <Typography>{readableNotificationType(bodyItem.template.notificationType)}</Typography>
            </Box>
            <Button
                endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
                onClick={() => setShowDetails(!showDetails)}
                sx={{ my: 1 }}
            >
                {showDetails ? 'Hide' : 'Show'} details
            </Button>
            <Collapse in={showDetails} sx={{ ml: 1 }}>
                <Details communication={bodyItem} invoiceIdentifier={bodyItem.invoiceIdentifier} />
            </Collapse>
        </Box>
    );
}
