import axios from 'axios';
import { PrintRequestSummary } from './notification';

export const downloadDocumentForPrinting = async (printRequest: PrintRequestSummary): Promise<Blob> => {
    return await axios
        .get(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/notifications/${printRequest.notificationIdentifier}/attachments/${printRequest.notificationAttachmentIdentifier}/download`,
            { responseType: 'blob' }
        )
        .then(({ data }) => data);
};

export const updateNotificationPrinted = async (printRequest: PrintRequestSummary): Promise<PrintRequestSummary> => {
    return await axios
        .put(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/notifications/${printRequest.notificationIdentifier}/printed`)
        .then(({ data }) => data);
};
