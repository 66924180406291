import {Box, FormControl, FormLabel, Paper, TextField, Typography} from '@mui/material';
import {useState} from 'react';
import {Save} from '@mui/icons-material';
import {Note} from '../../../types/Types';
import axios from 'axios';
import * as yup from 'yup';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup/dist/yup';
import {LoadingButton} from '../../../components/LoadingButton';
import {Client} from '../../../apis/clients';

interface NoteFormProps {
    client: Client;
    callback: (note: Note) => void;
}

interface FormFields {
    contents: string;
}

const schema = yup.object({
    contents: yup.string().required('Note contents required'),
});

const NoteForm = (props: NoteFormProps) => {
    const {client, callback} = props;
    const [saving, setSaving] = useState(false);

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<FormFields>({
        resolver: yupResolver(schema),
    });

    const onSubmit: SubmitHandler<FormFields> = (data) => {
        setSaving(true);

        const saveNoteRequestPromise = async (): Promise<Note> => {
            return await axios
                .post(process.env.REACT_APP_BROKER_PORTAL_HOST + '/clients/' + client.uuid + '/notes', data)
                .then((response) => {
                    return response.data;
                });
        };

        saveNoteRequestPromise()
            .then((note) => {
                callback(note);
            })
            .finally(() => setSaving(false));
    };

    return (
        <Paper variant='flat' sx={{p: 2, mt: 2}}>
            <Typography variant='h2'>Add Note</Typography>
            <form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth required>
                    <FormLabel>Note details</FormLabel>
                    <Controller
                        name='contents'
                        control={control}
                        render={({field}) => (
                            <TextField
                                {...field}
                                multiline
                                rows={10}
                                autoComplete='no'
                                error={errors.contents != undefined}
                                helperText={errors.contents?.message}
                            />
                        )}
                    />
                </FormControl>
                <Box
                    sx={{
                        textAlign: 'right',
                        position: 'absolute',
                        bottom: 0,
                        right: 10,
                        pb: 2,
                        width: '50%',
                        maxWidth: '370px',
                    }}
                >
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        fullWidth
                        size={'large'}
                        startIcon={<Save />}
                        loading={saving}
                    >
                        Save note
                    </LoadingButton>
                </Box>
            </form>
        </Paper>
    );
};

export default NoteForm;
