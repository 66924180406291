import { Close, HelpOutlined } from '@mui/icons-material';
import { Box, Chip, Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import { round, upperFirst } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import {
    ArrangementTransactionMapping,
    ExpectedPayment,
    ExpectedPaymentCausedByType,
    ExpectedPaymentStatus,
    ExpectedPaymentTransactionMapping,
    TransactionType,
} from '../apis/invoice';
import { Arrangement } from '../apis/variations';
import useDefaultFeeLabel from '../hooks/useDefaultFeeLabel';
import { DATE_COMPACT, DATE_TIME_FRIENDLY } from '../util/dateUtils';

type Props = {
    transactionType?: TransactionType;
    dueDate?: string;
    transactionDate?: string;
    amount: number;
    paidAmount?: number;
    feeAmount?: number;
    expectedPayments?: ExpectedPayment[];
    expectedPaymentTransactionMapping?: ExpectedPaymentTransactionMapping[];
    declinedResult?: string;
    arrangements?: Arrangement[];
    arrangementTransactionMapping?: ArrangementTransactionMapping[];
};

export default function DetailedPaymentAmount(payment: Readonly<Props>) {
    const [dialogOpen, setDialogOpen] = useState(false);

    const paidAmount = payment.paidAmount ?? 0;
    const feeAmount = payment.feeAmount ?? 0;
    const paymentMade = !payment.declinedResult && round(paidAmount - feeAmount, 2) === round(payment.amount, 2);
    const isPartiallyPaid = paidAmount > 0 && round(payment.amount, 2) > round(paidAmount, 2);

    const openDialog = () => {
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    return (
        <>
            {(paymentMade || payment.declinedResult) && (
                <Typography variant='caption'>{currencyFormat.format(paidAmount)}</Typography>
            )}
            {!paymentMade && !payment.declinedResult && (
                <Typography variant='caption'>
                    {currencyFormat.format(payment.amount - paidAmount + feeAmount)}
                </Typography>
            )}

            <IconButton onClick={openDialog}>
                <HelpOutlined fontSize='small' style={{ fontSize: '16px' }} />
            </IconButton>

            <Dialog onClose={closeDialog} open={dialogOpen} fullWidth maxWidth='xs'>
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton onClick={closeDialog}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }}>
                            {(paymentMade || payment.declinedResult) && (
                                <Typography variant='h3'>{currencyFormat.format(paidAmount)}</Typography>
                            )}
                            {!paymentMade && !payment.declinedResult && (
                                <Typography variant='h3'>
                                    {currencyFormat.format(payment.amount - paidAmount + feeAmount)}
                                </Typography>
                            )}
                            {paymentMade && payment.transactionType === TransactionType.PAYMENT && (
                                <Chip label='Paid' color='success' />
                            )}
                            {paymentMade && payment.transactionType === TransactionType.EXTERNAL_TRANSACTION && (
                                <Chip
                                    label={payment.amount < 0 ? 'Refunded externally' : 'Paid externally'}
                                    color='success'
                                />
                            )}
                            {payment.declinedResult && <Chip label={payment.declinedResult} color='error' />}
                        </Box>
                        <Box mb={4}>
                            <Typography variant='subtitle1'>Payment date</Typography>
                            {payment.dueDate && (
                                <Typography variant='caption'>
                                    {moment(payment.dueDate).format(DATE_COMPACT)}
                                </Typography>
                            )}
                            {payment.transactionDate && (
                                <Typography variant='caption'>
                                    {moment(payment.transactionDate).format(DATE_TIME_FRIENDLY)}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box>
                        {payment.expectedPayments?.map((ep) => (
                            <ExpectedPaymentRow key={ep.uuid} expectedPayment={ep} paymentMade={paymentMade} />
                        ))}
                        {payment.expectedPaymentTransactionMapping?.map((ept) => (
                            <ExpectedPaymentTransactionRow
                                key={ept.expectedPayment.uuid}
                                expectedPayment={ept.expectedPayment}
                                amountAllocated={ept.amountAllocated}
                            />
                        ))}
                        {payment.arrangements?.map((arrangement) => (
                            <PaymentRow key={arrangement.uuid} rowTitle='Arrangement' amount={arrangement.amount} />
                        ))}
                        {payment.arrangementTransactionMapping?.map((at) => (
                            <PaymentRow key={at.arrangement.uuid} rowTitle='Arrangement' amount={at.amountAllocated} />
                        ))}
                    </Box>
                    <Box mt={2}>
                        {isPartiallyPaid && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    - Payments made
                                </Typography>

                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(-paidAmount)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Subtotal
                            </Typography>
                            <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(
                                    paymentMade || payment.declinedResult ? payment.amount : payment.amount - paidAmount
                                )}
                            </Typography>
                        </Box>
                        {feeAmount > 0 && (
                            <Box sx={{ display: 'flex' }}>
                                <Typography variant='body2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                    + Transaction fee
                                </Typography>
                                <Typography variant='body2' sx={{ width: '100px', textAlign: 'right' }}>
                                    {currencyFormat.format(feeAmount)}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex' }}>
                            <Typography variant='subtitle2' sx={{ flexGrow: 1, textAlign: 'right' }}>
                                Total
                            </Typography>
                            <Typography variant='subtitle2' sx={{ width: '100px', textAlign: 'right' }}>
                                {currencyFormat.format(
                                    paymentMade || payment.declinedResult
                                        ? paidAmount
                                        : payment.amount - paidAmount + feeAmount
                                )}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

const PaymentRow = ({ rowTitle, amount }: { rowTitle: string; amount: number }) => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, mt: 2 }}>
                <Typography variant='subtitle2'>{rowTitle}</Typography>
                <Typography variant='subtitle2'>{currencyFormat.format(amount)}</Typography>
            </Box>
            <Divider />
        </>
    );
};

type ExpectedPaymentRowProps = {
    expectedPayment: ExpectedPayment;
    paymentMade: boolean;
};

const ExpectedPaymentRow = ({ expectedPayment, paymentMade }: Readonly<ExpectedPaymentRowProps>) => {
    const defaultFeeLabel = useDefaultFeeLabel();
    return (
        <PaymentRow
            rowTitle={getRowTitle(expectedPayment, defaultFeeLabel)}
            amount={paymentMade ? expectedPayment.paidAmount ?? 0 : expectedPayment.amount}
        />
    );
};

type ExpectedPaymentTransactionRowProps = {
    expectedPayment: ExpectedPayment;
    amountAllocated: number;
};

const ExpectedPaymentTransactionRow = ({
    expectedPayment,
    amountAllocated,
}: Readonly<ExpectedPaymentTransactionRowProps>) => {
    const defaultFeeLabel = useDefaultFeeLabel();
    return <PaymentRow rowTitle={getRowTitle(expectedPayment, defaultFeeLabel)} amount={amountAllocated} />;
};

const getRowTitle = (expectedPayment: ExpectedPayment, defaultFeeLabel: string) => {
    if (expectedPayment.causedBy === ExpectedPaymentCausedByType.DEFAULT_FEE) {
        return upperFirst(defaultFeeLabel);
    }

    if (expectedPayment.causedBy === ExpectedPaymentCausedByType.CANCELLATION_PRORATA_PAYMENT) {
        return 'Cancellation repayment adjustment';
    }

    if (expectedPayment.status === ExpectedPaymentStatus.OVERDUE) {
        return `Overdue repayment (due on ${moment(expectedPayment.dueDate).format(DATE_COMPACT)})`;
    }

    return 'Regular repayment';
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
