import {AutoFixNormal, Edit, Link} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {amber, grey} from '@mui/material/colors';
import {uniq} from 'lodash';
import {BaseClient} from '../../../apis/clients';

type Props = {
    client: BaseClient;
    change?: () => void;
    isSuggested?: boolean;
    isLinked?: boolean;
};

export default function ExistingClient({client, change, isSuggested, isLinked}: Readonly<Props>) {
    const addr = client.contactDetails.preferredAddress;
    const addrArray = uniq([addr.addressLine1, addr.addressLine2, addr.suburb, addr.city]).filter((value) => value);
    return (
        <Box sx={{display: 'inline-flex', alignItems: 'center', gap: 4, p: 2, bgcolor: grey[100]}}>
            <Box>
                {isSuggested && (
                    <Box sx={{display: 'flex', gap: 1, alignItems: 'end'}}>
                        <AutoFixNormal color='inherit' fontSize='small' sx={{color: amber[600]}}></AutoFixNormal>
                        <Typography variant='subtitle1'>Suggested</Typography>
                    </Box>
                )}
                {isLinked && (
                    <Box sx={{display: 'flex', gap: 1, alignItems: 'center'}}>
                        <Link color='inherit' fontSize='small' sx={{color: amber[600]}}></Link>
                        <Typography variant='subtitle1'>Linked</Typography>
                    </Box>
                )}
                <Box py={1}>
                    <Typography>{client.displayName ?? 'Unknown Client'}</Typography>
                </Box>
                <Box>
                    <Typography variant='caption'>
                        {client.contactDetails.email.address}
                        {client.contactDetails.preferredPhoneNumber.number &&
                            ' • ' + client.contactDetails.preferredPhoneNumber.number}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='caption'>
                        {addrArray.join(', ')} {addr.postCode}
                    </Typography>
                </Box>
            </Box>
            {change && (
                <Button startIcon={<Edit />} onClick={change}>
                    Change
                </Button>
            )}
        </Box>
    );
}
