import { Box } from '@mui/material';
import { CustomCheckoutType, InvoiceDetailsResponse, InvoiceStatus, MigrationStatus } from '../../../apis/invoice';
import { isSuccess } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import ClientDetailsCard from './ClientDetailsCard';
import PaymentSummaryCard from './PaymentSummaryCard';
import PremiumDetailsCard from './PremiumDetailsCard';
import PolicyOptionsCard from './PolicyOptionsCard';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
};

export default function OverviewCards({ invoiceDetails }: Readonly<Props>) {
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const externalProvider = invoiceDetails.invoice.invoiceExternalProviderDetail;
    const externalLoan = externalProvider != null && externalProvider.checkoutType !== CustomCheckoutType.NONE;
    const partialMigration = invoiceDetails.invoice.migrationStatus === MigrationStatus.PARTIAL;

    return (
        <Box sx={{ display: 'flex', gap: 2, width: { md: 300 }, minWidth: 300, flexDirection: 'column' }}>
            <ClientDetailsCard client={invoiceDetails.client} />
            {isSuccess(loanState) &&
                loanState.value != null &&
                invoiceDetails.invoice.status !== InvoiceStatus.CANCELLED &&
                !externalLoan &&
                !partialMigration && <PaymentSummaryCard invoiceDetails={invoiceDetails} loan={loanState.value} />}
            {!externalLoan && !partialMigration && (
                <>
                    <PremiumDetailsCard invoiceDetails={invoiceDetails} />
                    <PolicyOptionsCard invoice={invoiceDetails.invoice} />
                </>
            )}
        </Box>
    );
}
