import { Box, Typography } from '@mui/material';
import { ActivityVariation } from '../../apis/enquiry';
import EnquiryActivityAction from './actionButtons/EnquiryActivityAction';
import Activities from './Activities';

type Props = {
    uuid?: string;
    enquiryContactDateTime?: string;
    enquiryActivities: ActivityVariation[];
    setEnquiryActivities: (newActivities: ActivityVariation[]) => void;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
};
export default function EnquiryActivitySection({
    uuid,
    enquiryContactDateTime,
    enquiryActivities,
    setEnquiryActivities,
    addNewActivityCallback,
}: Readonly<Props>) {
    return (
        <Box sx={{ my: 6 }}>
            <Box
                width='100%'
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 1,
                }}
            >
                <Typography variant='h6' component='h2'>
                    Activity
                </Typography>
                <EnquiryActivityAction
                    enquiryIdentifier={uuid as string}
                    enquiryContactDateTime={enquiryContactDateTime}
                    addNewActivityCallback={addNewActivityCallback}
                />
            </Box>
            <Activities
                enquiryIdentifier={uuid as string}
                enquiryContactDateTime={enquiryContactDateTime as string}
                enquiryActivities={enquiryActivities}
                setEnquiryActivities={setEnquiryActivities}
            />
        </Box>
    );
}
