import {Chip} from '@mui/material';
import {PolicyVersionStatus} from "../apis/quotes";

type Props = {
    status: PolicyVersionStatus;
};

export default function PolicyStatusChip({status}: Readonly<Props>) {
    switch (status) {
        case PolicyVersionStatus.READY_FOR_RENEWAL:
        case PolicyVersionStatus.PENDING_RENEWAL:
            return <Chip label='In progress' color='info' variant='outlined' />;
        case PolicyVersionStatus.ACTIVE:
            return <Chip label='In force' color='success' variant='outlined' />;
        case PolicyVersionStatus.NOT_ACTIVE:
            return <Chip label='Not active' color='error' variant='outlined' />;
        default:
            return <Chip label='No Status' variant='outlined' />;
    }
}
