import { Box, Divider, Typography } from '@mui/material';
import { ActivityVariation, EnquiryStatus } from '../../apis/enquiry';
import EnquiryAssigneeButton from '../../components/EnquiryAssigneeButton';
import EnquiryStatusAction from './actionButtons/EnquiryStatusAction';

type Props = {
    uuid?: string;
    status?: EnquiryStatus;
    latestAssigneeIdentifier?: string;
    latestAssigneeName?: string;
    addNewActivityCallback?: (newActivityCreated: ActivityVariation) => void;
    enquiryStatusUpdateCallback?: (newEnquiryStatus: EnquiryStatus) => void;
};

export default function EnquirySubHeaderSection({
    uuid,
    status,
    latestAssigneeIdentifier,
    latestAssigneeName,
    addNewActivityCallback,
    enquiryStatusUpdateCallback,
}: Readonly<Props>) {
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 1,
                flexDirection: 'row',
                mb: 4,
                justifyContent: { xs: 'space-between', sm: 'flex-start' },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', pr: 2 }}>
                <Typography variant='caption' sx={{ pl: 0 }}>
                    Status
                </Typography>
                <EnquiryStatusAction
                    enquiryIdentifier={uuid as string}
                    enquiryStatus={status}
                    addNewActivityCallback={addNewActivityCallback}
                    enquiryStatusUpdateCallback={enquiryStatusUpdateCallback}
                />
            </Box>
            <Box>
                <Divider orientation='vertical' />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant='caption' sx={{ pl: 2 }}>
                    Assigned to
                </Typography>
                <EnquiryAssigneeButton
                    enquiryIdentifier={uuid as string}
                    assigneeIdentifier={latestAssigneeIdentifier}
                    assigneeName={latestAssigneeName}
                    addNewActivityCallback={addNewActivityCallback}
                />
            </Box>
        </Box>
    );
}
