import { AddCircleOutline } from '@mui/icons-material';
import { Box, Button, Drawer, Paper } from '@mui/material';
import { useState } from 'react';
import { Client } from '../../../apis/clients';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { Note } from '../../../types/Types';
import NoteForm from './NoteForm';
import ClientNote from './ClientNote';

export type LoadedNotesPageProps = {
    client: Client;
};

type Props = LoadedNotesPageProps & {
    data: Note[];
};

export default function LoadedNotesPage({ client, data }: Readonly<Props>) {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed } = permissions;
    const [notes, setNotes] = useState(data);
    const [addNote, setAddNote] = useState(false);

    const addNoteCallback = (note: Note) => {
        setNotes((existingNotes) => [...existingNotes, note]);
        setAddNote(false);
    };

    const renderAddingNote = () => {
        if (!clientUpdateAllowed) return;

        return (
            <>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        gap: 2,
                        alignItems: 'center',
                    }}
                >
                    <Button variant='outlined' startIcon={<AddCircleOutline />} onClick={() => setAddNote(true)}>
                        Add note
                    </Button>
                </Box>
                <Drawer
                    anchor='right'
                    open={addNote}
                    onClose={() => setAddNote(false)}
                    PaperProps={{
                        sx: { width: '50%', maxWidth: '1200px' },
                    }}
                >
                    <NoteForm client={client} callback={addNoteCallback} />
                </Drawer>
            </>
        );
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <Paper variant='flat' sx={{ p: 2 }}>
                {renderAddingNote()}
                <ClientNote notes={notes} />
            </Paper>
        </Box>
    );
}
