import { PrintRequestSummary } from '../../apis/notification';
import { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { downloadDocumentForPrinting, updateNotificationPrinted } from '../../apis/print';
import qz, { PrinterOptions } from 'qz-tray';

type Props = {
    printRequests: PrintRequestSummary[];
    printingComplete: () => void;
    closeModal: () => void;
};

export default function PrintDialog({ printRequests, printingComplete, closeModal }: Readonly<Props>) {
    const [printMessage, setPrintMessage] = useState('Batch print starting');
    const [printProgress, setPrintProgress] = useState('0/' + printRequests.length);
    const [printPercent, setPrintPercent] = useState(0);
    const [printing, setPrinting] = useState(false);
    const [complete, setComplete] = useState(false);
    const [printers, setPrinters] = useState(['']);
    const [selectedPrinter, setSelectedPrinter] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [failedLetters, setFailedLetters] = useState<PrintRequestSummary[]>([]);

    const selectPrinter = (e: SelectChangeEvent) => {
        setSelectedPrinter(e.target.value);
    };

    const fetchPrinters = () => {
        qz.websocket
            .connect()
            .then(() => {
                return qz.printers.find();
            })
            .then((foundPrinters) => {
                const printerArray = (typeof foundPrinters === 'string' ? [foundPrinters] : foundPrinters) as string[];
                setPrinters(printerArray);
            })
            .catch((err: string) => {
                setErrorMessage(err);
            })
            .finally(() => {
                qz.websocket.disconnect();
            });
    };

    const printDocuments = () => {
        setPrinting(true);
        qz.websocket.connect().then(() => {
            fetchAndPrintDocuments(selectedPrinter);
        });
    };

    useEffect(() => {
        fetchPrinters();
    }, [printRequests]);

    const fetchAndPrintDocuments = (printer: string) => {
        const config = qz.configs.create(printer);

        const fetchAndPrint = Promise.allSettled(
            printRequests.map((printRequest, index) => printRequestPromise(printRequest, index, config))
        );

        fetchAndPrint
            .then(() => {
                setPrintPercent(100);
                setPrintMessage('');
                setComplete(true);
            })
            .catch((err) => {
                setErrorMessage(err);
            })
            .finally(() => {
                qz.websocket.disconnect();
            });
    };

    const printRequestPromise = async (printRequest: PrintRequestSummary, index: number, config: PrinterOptions) => {
        setPrintMessage('Printing document: ' + printRequest.fileName + '.');
        console.log(config);
        return await downloadDocumentForPrinting(printRequest)
            .then((data) => {
                return data.arrayBuffer();
            })
            .then((buffer) => {
                return qz.print(config, [
                    {
                        type: 'pixel',
                        format: 'pdf',
                        flavor: 'base64',
                        data: new Uint8Array(buffer),
                    },
                ]);
            })
            .then(() => {
                setPrintPercent(((index + 1) / printRequests.length) * 100);
                setPrintProgress(index + 1 + '/' + printRequests.length);
                return updateNotificationPrinted(printRequest);
            })
            .catch((err) => {
                console.error(err);
                setFailedLetters([...failedLetters, printRequest]);
            });
    };

    return (
        <Box>
            <DialogTitle>Printing letters</DialogTitle>
            <DialogContent sx={{ width: 480 }}>
                {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}

                {printing ? (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant='determinate' value={printPercent} />
                            </Box>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant='body2'>{printProgress}</Typography>
                            </Box>
                        </Box>
                        <Typography variant='body2'>{printMessage}</Typography>
                    </>
                ) : (
                    <Box sx={{ display: 'block' }}>
                        <Typography variant='body2'>Select printer</Typography>
                        <FormControl fullWidth>
                            <Select onChange={selectPrinter}>
                                {printers.map((printer) => (
                                    <MenuItem key={printer} value={printer}>
                                        {printer}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                )}

                {complete && failedLetters.length > 0 && (
                    <Alert severity='error'>
                        The following documents failed to print:
                        {failedLetters.map((printRequest) => (
                            <Box key={printRequest.notificationAttachmentIdentifier}>{printRequest.fileName}</Box>
                        ))}
                    </Alert>
                )}
            </DialogContent>

            {!printing && (
                <DialogActions>
                    <Button variant='text' color='primary' onClick={closeModal}>
                        Close
                    </Button>
                    <Button variant='contained' onClick={printDocuments}>
                        Print
                    </Button>
                </DialogActions>
            )}

            {complete && (
                <DialogActions>
                    <Button variant='contained' onClick={printingComplete}>
                        Close
                    </Button>
                </DialogActions>
            )}
        </Box>
    );
}
